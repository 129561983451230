import React, { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { PanelGroup, Panel, PanelResizeHandle } from 'react-resizable-panels';
import { useLocation } from 'react-router-dom';
import LeftInterface from './LeftInterface';
import RightInterface from './RightInterface';

const POLLING_INTERVAL = 10000; // Polling interval in milliseconds

const Conversation = () => {
  const location = useLocation();
  const { conv_ID } = useParams();
  const [messages, setMessages] = useState([]);
  const [data, setData] = useState(null);
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    const fetchConversation = async () => {
      if (!conv_ID) {
        console.error('conv_ID is not set');
        return;
      }

      try {
        const response = await fetch(`${BACKEND_URL}/auth/get-conversation/${conv_ID}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          signal,
        });

        if (response.ok) {
          const conversationData = await response.json();
          if (conversationData && conversationData.conversation) {
            // Only update state if the new data is different
            setMessages(prevMessages => {
              const newMessages = conversationData.conversation.queries;
              if (JSON.stringify(prevMessages) !== JSON.stringify(newMessages)) {
                return newMessages;
              }
              return prevMessages;
            });
            // setData(prevData => {
            //   const newData = conversationData.conversation;
            //   if (JSON.stringify(prevData) !== JSON.stringify(newData)) {
            //     return newData;
            //   }
            //   return prevData;
            // });
          } else {
            console.log('No conversation data found');
          }
        } else {
          const errorData = await response.text();
          console.log('Error fetching conversation data:', errorData);
        }
      } catch (error) {
        if (error.name !== 'AbortError') {
          console.error('Error fetching conversation:', error);
        }
      }
    };

    // Initial fetch
    fetchConversation();

    // Polling
    const intervalId = setInterval(fetchConversation, POLLING_INTERVAL);

    // Cleanup on component unmount
    return () => {
      clearInterval(intervalId);
      controller.abort();
    };
  }, [conv_ID]);

  useEffect(() => {
    // console.log('Messages state updated:', messages);
  }, [messages]);

  const memoizedMessages = useMemo(() => messages, [messages]);
  // console.log(memoizedMessages)

  return (
    <div className="h-screen flex">
      <PanelGroup autoSaveId="persistence" direction="horizontal">
        <Panel defaultSize={50} minSize={20}>
          <div className="h-full p-4 pt-1">
            <LeftInterface messages={memoizedMessages} />
          </div>
        </Panel>
        <PanelResizeHandle className="w-1 bg-gray-200 cursor-col-resize" />
        <Panel defaultSize={50} minSize={20}>
          <div className="h-full p-4 pt-1">
            <RightInterface messages={memoizedMessages} />
          </div>
        </Panel>
      </PanelGroup>
    </div>
  );
};

export default Conversation;