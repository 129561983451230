import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FiBookmark, FiEdit3, FiTrash2, FiArrowUp, FiArrowDown } from 'react-icons/fi';
import { RxBookmarkFilled, RxBookmark } from "react-icons/rx";

const HistoryPage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [conversations, setConversations] = useState([]);
  const [editingConversation, setEditingConversation] = useState(null);
  const [newHistoryTitle, setNewHistoryTitle] = useState('');
  const [deletingConversation, setDeletingConversation] = useState(null);
  const [filterBookmarked, setFilterBookmarked] = useState(false);
  const [sortOption, setSortOption] = useState('date-desc');
  const [isExpanded, setIsExpanded] = useState(false);
  const [deleteModalContent, setDeleteModalContent] = useState(null);
  const [deleteBoth, setDeleteBoth] = useState(false);
  
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  

  useEffect(() => {
    const fetchConversations = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/auth/get-conversations`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setConversations(response.data.conversations.reverse());
      } catch (error) {
        console.error('Error fetching conversations:', error);
      }
    };

    fetchConversations();
  }, [token, conversations]);

  const sortConversations = (conversations) => {
    switch (sortOption) {
      case 'date-asc':
        return conversations.sort((a, b) => new Date(a.queries[a.queries.length - 1].Time) - new Date(b.queries[b.queries.length - 1].Time));
      case 'date-desc':
        return conversations.sort((a, b) => new Date(b.queries[b.queries.length - 1].Time) - new Date(a.queries[a.queries.length - 1].Time));
      case 'title-asc':
        return conversations.sort((a, b) => (a.Summary[0]?.history_title || '').localeCompare(b.Summary[0]?.history_title || ''));
      case 'title-desc':
        return conversations.sort((a, b) => (b.Summary[0]?.history_title || '').localeCompare(a.Summary[0]?.history_title || ''));
      default:
        return conversations;
    }
  };

  const filteredConversations = sortConversations(
    conversations
      .filter(conversation =>
        (conversation.queries.length > 0 && 
       ( conversation.queries[0].QueryName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        conversation.queries[0].response.toLowerCase().includes(searchTerm.toLowerCase())
       )
      )
      )
      .filter(conversation => filterBookmarked ? conversation.bookmarked : true)
  );

  // Function to group conversations by date
  const groupConversationsByDate = (conversations) => {
    const groups = {};

    conversations.forEach(conversation => {
      const lastQueryTime = new Date(conversation.queries[conversation.queries.length - 1].Time);
      const now = new Date();

      const diffTime = now - lastQueryTime;
      const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

      let category;

      if (diffDays === 0) {
        category = 'Today';
      } else if (diffDays <= 7) {
        category = 'Last week';
      } else if (diffDays <= 30) {
        category = 'Last month';
      } else {
        category = 'Older';
      }

      if (!groups[category]) {
        groups[category] = [];
      }
      groups[category].push(conversation);
    });

    return groups;
  };

  const groupedConversations = groupConversationsByDate(filteredConversations);
  const handleConversationClick = (conv_ID) => {
    navigate(`/c/${conv_ID}`);
  };

  const openEditPopup = (e, conversation) => {
    e.stopPropagation(); // Prevent event bubbling

    // Toggle logic: if the same conversation is being edited, close the popup
    if (editingConversation?.conversationID === conversation.conversationID) {
      setEditingConversation(null); // Close popup
      setNewHistoryTitle(''); // Reset title input
    } else {
      // Open popup and set initial title
      const currentTitle = conversation.Summary?.[0]?.history_title || conversation.queries?.[0]?.QueryName || '';
      setEditingConversation(conversation); // Open popup for this conversation
      setNewHistoryTitle(currentTitle); // Set initial value for editing
    }
  };


  const handleSave = async () => {
    try {
      const updatedSummary = { ...editingConversation.Summary[0], history_title: newHistoryTitle };
      const updatedConversation = {
        ...editingConversation,
        Summary: [updatedSummary]
      };
      await axios.post(`${BACKEND_URL}/auth/save-conversation`, { conversations: updatedConversation }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setConversations(prevConversations =>
        prevConversations.map(conv =>
          conv.conversationID === editingConversation.conversationID ? updatedConversation : conv
        )
      );
      setEditingConversation(null);
      setNewHistoryTitle('');
    } catch (error) {
      console.error('Error saving conversation:', error);
    }
  };

  const handleConversation = async (event, conversation) => {
    setDeletingConversation(conversation);
    try {
      const response = await fetch(`${BACKEND_URL}/auth/check-edited-molecules/${conversation.conversationID}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (response.ok) {
        const data = await response.json();
        if (data.hasEditedMolecules) {
          // Show modal with checkbox for conversations with edited molecules
          setDeleteModalContent({
            hasCheckbox: true,
            message: `You have edited ${data.totalMolecules} ${data.totalMolecules === 1 ? 'molecule' : 'molecules'} across ${data.count} ${data.count === 1 ? 'query' : 'queries'} in this investigation. Do you want to delete them along with the investigation?`
            ,
          });
        } else {
          // Show modal for conversations without edited molecules
          setDeleteModalContent({
            hasCheckbox: false,
            message: 'Are you sure you want to delete this investigation?',
          });
        }
      } else {
        console.error('Failed to check edited molecules. Status:', response.status);
      }
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.error('Error checking edited molecules:', error);
      }
    }
  };

// Delete only the conversation queries
  const handleEmptyConvQueries = async () => {
    try {
      await axios.delete(`${BACKEND_URL}/auth/empty-conv-queries/${deletingConversation.conversationID}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setConversations((prevConversations) =>
        prevConversations.map((conv) =>
          conv.conversationID === deletingConversation.conversationID
            ? { ...conv, queries: [] } // Empty the queries locally
            : conv
        )
      );
      setDeletingConversation(null);
      navigate('/home');
    } catch (error) {
      console.error('Error emptying conversation queries:', error);
    }
  };
// Delete both conversation and molecules
  const handleDeleteBoth = async () => {
    try {
      await axios.delete(`${BACKEND_URL}/auth/delete-both/${deletingConversation.conversationID}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setConversations((prevConversations) =>
        prevConversations.filter((conv) => conv.conversationID !== deletingConversation.conversationID)
      );
      setDeletingConversation(null);
      navigate('/home');
    } catch (error) {
      console.error('Error deleting both:', error);
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`${BACKEND_URL}/auth/delete-conversation/${deletingConversation.conversationID}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setConversations(prevConversations =>
        prevConversations.filter(conv => conv.conversationID !== deletingConversation.conversationID)
      );
      setDeletingConversation(null);
    } catch (error) {
      console.error('Error deleting conversation:', error);
    }
  };

  const confirmDelete = (event, conversation) => {
    event.stopPropagation();
    setDeletingConversation(conversation);
  };

  const cancelDelete = () => {
    setDeletingConversation(null);
  };

  const toggleBookmark = async (event, conversation) => {
    event.stopPropagation();
    try {
      const response = await axios.post(`${BACKEND_URL}/auth/toggle-bookmark/${conversation.conversationID}`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      const updatedBookmarkedStatus = response.data.bookmarked;

      setConversations(prevConversations =>
        prevConversations.map(conv =>
          conv.conversationID === conversation.conversationID ? { ...conv, bookmarked: updatedBookmarkedStatus } : conv
        )
      );
    } catch (error) {
      console.error('Error toggling bookmark:', error);
    }
  };

  return (
    <div className="h-screen bg-gray-50 flex">
      <div className="flex-grow p-4">
        <div className="max-w-7xl mx-auto p-4">
          <div className="flex items-center justify-between mb-2">
            <input
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full p-2 border rounded-md"
            />
          </div>

          <div className="flex justify-between items-center mb-4">
            <button className="flex flex-row p-2 items-center bg-gray-200 rounded-md rounded hover:bg-[#DDE0E5] transition duration-200" onClick={() => setFilterBookmarked(!filterBookmarked)}>
              {filterBookmarked ? 'Show All' : 'Show Bookmarked'}
            </button>

            <div className="flex space-x-4">
              <button className="flex flex-row p-2 items-center bg-gray-200 rounded-md rounded hover:bg-[#DDE0E5] transition duration-200" title={sortOption === 'date-asc' ? 'Sort Ascending' : 'Sort Descending'} onClick={() => setSortOption(sortOption === 'date-asc' ? 'date-desc' : 'date-asc')}>
                {sortOption === 'date-asc' ? <FiArrowUp /> : <FiArrowDown />}
                <span className="ml-2">Date</span>
              </button>

              <button className="flex flex-row p-2 items-center bg-gray-200 rounded-md rounded hover:bg-[#DDE0E5] transition duration-200" title={sortOption === 'date-asc' ? 'Sort Ascending' : 'Sort Descending'} onClick={() => setSortOption(sortOption === 'title-asc' ? 'title-desc' : 'title-asc')}>
                {sortOption === 'title-asc' ? <FiArrowUp /> : <FiArrowDown />}
                <span className="ml-2">Title</span>
              </button>
            </div>
          </div>

          {Object.keys(groupedConversations).map((category) => (
            <div key={category}>
              <h2 className="text-xl font-semibold mt-2 mb-2">{category}</h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                {groupedConversations[category].filter(conversation => conversation.queries.length > 0).map((conversation) => {
                  const summary = conversation.Summary && conversation.Summary.length > 0 ? conversation.Summary[0] : {};
                  // const historyTitle = summary.history_title ? summary.history_title.substring(0, 16) : 'New Chat';
                  // const historySummary = summary.history_summary ? summary.history_summary.substring(0, 80) : 'Summary';
                  const historyTitle = conversation.Summary?.[0]?.history_title || conversation.queries?.[0]?.QueryName || 'New Chat';
                  const isBookmarked = conversation.bookmarked;
                  const lastQueryTime = conversation.queries.length > 0 ? conversation.queries[conversation.queries.length - 1].Time : 'No timestamp';
                  const historySummary = summary.history_summary || 'Summary';
                  const truncatedSummary = historySummary.length > 80
                    ? historySummary.substring(0, 80)
                    : historySummary;
                  return (
                    <div
                      key={conversation.conversationID}
                      className="bg-[#EEEFF2] shadow-md p-4 rounded-lg flex flex-col justify-between cursor-pointer"
                      onClick={() => handleConversationClick(conversation.conversationID)}
                    >
                      <div className="flex justify-between items-center">
                        {editingConversation?.conversationID === conversation.conversationID ? (
                          // If in edit mode, show input field
                          <input
                            type="text"
                            value={newHistoryTitle}
                            onChange={(e) => setNewHistoryTitle(e.target.value)}
                            className="text-lg font-semibold mb-2 border p-1 rounded-md w-[200px]"
                            // onBlur={handleSave} 
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') handleSave(); // Save on Enter key press
                            }}
                            autoFocus // Automatically focus the input
                            // ref={(input) => input && input.setSelectionRange(newHistoryTitle.length, newHistoryTitle.length)} // Move cursor to the end
                            ref={(input) => {
                              if (input && !input._hasSetSelection) { // Prevent overriding after first focus
                                input.setSelectionRange(newHistoryTitle.length, newHistoryTitle.length);
                                input._hasSetSelection = true; // Custom property to track if selection was set
                              }
                            }}
                          />
                        ) : (
                          // Otherwise, display the title as text
                          // <h3 className="text-lg font-semibold mb-2 break-all ">{historyTitle}</h3>
                          <h3 className="text-lg font-semibold mb-2 break-all ">
                            {historyTitle.length > 80 ? `${historyTitle.substring(0, 80)}...` : historyTitle}
                          </h3>
                        )}
                        <button
                          className="p-2 text-[#49536E]"
                          title='Edit Title'
                          onClick={(e) => openEditPopup(e, conversation)}
                        >
                          <FiEdit3 />
                        </button>
                      </div>

                      {/* <p className="text-gray-700 mb-4">{historySummary}...</p> */}
                      <p className="text-gray-700 mb-4">
                        {isExpanded ? historySummary : truncatedSummary}
                        {historySummary.length > 80 && (
                          <span
                            className="text-blue-500 cursor-pointer ml-2"
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent triggering parent click
                              setIsExpanded(!isExpanded);
                            }}
                          >
                            {isExpanded ? ' Show less' : '... Show more'}
                          </span>
                        )}
                      </p>
                      <p className="text-gray-500 text-sm">Last edited: {lastQueryTime}</p>
                      <div className="flex justify-between items-center">
                        <div className="flex space-x-2">
                          <button className="p-2 text-[#49536E]"
                            title={isBookmarked ? 'Unbookmark' : 'Bookmark'}
                            onClick={(e) => toggleBookmark(e, conversation)}>
                            {isBookmarked ? <RxBookmarkFilled /> : <RxBookmark />}
                          </button>
                          <button title="Delete" 
                          className="p-2 text-[#FF9783]" 
                          onClick={(e) => {
                            e.stopPropagation();
                            handleConversation(e, conversation);
                          }}>
                            <FiTrash2 />
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Edit Popup */}
      {/* {editingConversation && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <h3 className="text-lg font-semibold mb-4">Edit History Title</h3>
            <input
              type="text"
              value={newHistoryTitle}
              onChange={(e) => setNewHistoryTitle(e.target.value)}
              className="w-full p-2 border rounded-md mb-4"
            />
            <div className="flex justify-end">
              <button className="p-2 bg-gray-200 rounded-md mr-2" onClick={() => setEditingConversation(null)}>Cancel</button>
              <button className="p-2 bg-blue-500 text-white rounded-md" onClick={handleSave}>Save</button>
            </div>
          </div>
        </div>
      )} */}

      {/* Delete Confirmation Popup */}
      {deletingConversation && deleteModalContent && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <h3 className="text-lg font-semibold mb-4 prose">{deleteModalContent.message}</h3>

            {deleteModalContent.hasCheckbox && (
              // Display checkbox only for conversations with edited molecules
              <div className="flex items-center mb-4">
                <input
                  type="checkbox"
                  id="deleteBothCheckbox"
                  checked={deleteBoth}
                  onChange={(e) => setDeleteBoth(e.target.checked)}
                  className="mr-2"
                />
                <label htmlFor="deleteBothCheckbox" className="text-sm text-gray-600">
                  Delete edited molecules along with the investigation
                </label>
              </div>
            )}

            <div className="flex justify-end space-x-2">
              <button
                className="p-2 rounded-md bg-gray-200"
                onClick={cancelDelete}
              >
                Cancel
              </button>
              <button
                className="p-2 rounded-md bg-red-500 text-white"
                onClick={() => {
                  // Handle delete actions based on the checkbox
                  if (deleteModalContent.hasCheckbox) {
                    // If checkbox is present (i.e., there are edited molecules)
                    deleteBoth
                      ? handleDeleteBoth() 
                      : handleEmptyConvQueries();  
                  } else {
                    // If no checkbox (i.e., no edited molecules), just delete the conversation
                    handleDelete();
                  }
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HistoryPage;