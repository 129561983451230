import React, { useState, useEffect, useMemo } from 'react';
import TableView from './TableView';
import AdmetPopup from './AdmetPopup';
import MoleculeEditorPopup from './MoleculeEditorPopup';
import OCL from 'openchemlib';
import { useDispatch, useSelector } from 'react-redux';
import { fetchParameters, userDetails } from '../../redux/Actions/action';
import axios from 'axios';
import DockingPopup from './DockingPopup';
import db from '../../db'; // Dexie instance
import MergeTableView from './MergeTableView';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const PredictNovelDrugsInterface = (props) => {
  // -----------------------------
  // STATE & REDUX
  // -----------------------------
  const [tableData, setTableData] = useState({ repurposeableDrugs: [], KnownDrugs: [] });
  const [selectedAdmet, setSelectedAdmet] = useState(null);
  const [selectedDrugName, setSelectedDrugName] = useState('');
  const [editSmiles, setEditSmiles] = useState(null);
  const [dockSmiles, setDockSmiles] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  // Redux
  const dispatch = useDispatch();
  const conv_ID = useSelector((state) => state.conv_ID);
  const UserData = useSelector((state) => state.UserDetails);
  const novelParameters = useSelector((state) => state.parameters?.predictNovelDrugs) || {};
  const dockParameters = useSelector((state) => state.parameters?.dock) || {};

  // In your code, `data` is passed as props. We’ll rename to `props.data` for clarity.
  const proteinId = useMemo(() => props?.data?.PROTEIN, [props]);
  const proteinName = useMemo(() => props?.data?.NAME, [props]);

  // -----------------------------
  // NOVEL DRUG PARAMETERS (Defaults from Redux or fallback)
  // -----------------------------
  const defaultIterations = novelParameters.iterations ?? 1;
  const defaultTopK = novelParameters.topK ?? 100;
  const defaultSeedProbThreshold = novelParameters.seedProbThreshold ?? 0.75;
  const defaultIterProbThreshold = novelParameters.iterProbThreshold ?? 0.7;
  const defaultSimSearchThreshold = novelParameters.simSearchThreshold ?? 0.75;
  const defaultSimSearchWorkers = novelParameters.simSearchWorkers ?? 1;

  // -----------------------------
  // PAST PARAMETERS (Dropdown)
  // -----------------------------
  const [pastParameters, setPastParameters] = useState([]);
  const [selectedParameter, setSelectedParameter] = useState(null);

  // We parse final parameters from either the user’s dropdown selection or from defaults
  const derivedIterations = selectedParameter?.iterations ?? defaultIterations;
  const derivedTopK = selectedParameter?.topK ?? defaultTopK;
  const derivedSeedT = selectedParameter?.seedProbThreshold ?? defaultSeedProbThreshold;
  const derivedIterT = selectedParameter?.iterProbThreshold ?? defaultIterProbThreshold;
  const derivedSimT = selectedParameter?.simSearchThreshold ?? defaultSimSearchThreshold;
  const derivedWorkers = selectedParameter?.simSearchWorkers ?? defaultSimSearchWorkers;

  // -----------------------------
  // LOCAL STORAGE
  // -----------------------------
  // Keep the user’s dropdown selection so it persists across navigations
  useEffect(() => {
    if (!proteinId) return;
    const storageKey = `pnParam_${proteinId}`;
    const storedJSON = localStorage.getItem(storageKey);
    if (storedJSON) {
      try {
        const parsed = JSON.parse(storedJSON);
        setSelectedParameter(parsed);
      } catch {
        // ignore parse errors
      }
    }
  }, [proteinId]);

  useEffect(() => {
    if (!proteinId) return;
    const storageKey = `pnParam_${proteinId}`;
    if (selectedParameter) {
      localStorage.setItem(storageKey, JSON.stringify(selectedParameter));
    } else {
      localStorage.removeItem(storageKey);
    }
  }, [selectedParameter, proteinId]);

  // -----------------------------
  // BUILD A CACHE KEY
  // -----------------------------
  // Example format:
  // novel_<proteinId>_iter2_topK200_seedT0.8_iterT0.7_simT0.75_workers1
  const cacheKey = useMemo(() => {
    if (!proteinId) return null;
    return `novel_${proteinId}_iter${derivedIterations}_topK${derivedTopK}_seedT${derivedSeedT}_iterT${derivedIterT}_simT${derivedSimT}_workers${derivedWorkers}`;
  }, [proteinId, derivedIterations, derivedTopK, derivedSeedT, derivedIterT, derivedSimT, derivedWorkers]);

  // -----------------------------
  // EXTRACT PARAMS FROM KEY
  // -----------------------------
  // Opposite of the above format
  const extractParamFromKey = (key) => {
    const regex = new RegExp(
      `^novel_(\\w+)_iter(\\d+)_topK(\\d+)_seedT([\\d.]+)_iterT([\\d.]+)_simT([\\d.]+)_workers(\\d+)$`
    );
    const match = key.match(regex);
    if (!match) return null;
    // match[1] => proteinId
    // match[2] => iterations
    // match[3] => topK
    // match[4] => seedProbThreshold
    // match[5] => iterProbThreshold
    // match[6] => simSearchThreshold
    // match[7] => simSearchWorkers
    return {
      proteinId: match[1],
      iterations: parseInt(match[2], 10),
      topK: parseInt(match[3], 10),
      seedProbThreshold: parseFloat(match[4]),
      iterProbThreshold: parseFloat(match[5]),
      simSearchThreshold: parseFloat(match[6]),
      simSearchWorkers: parseInt(match[7], 10),
    };
  };

  // -----------------------------
  // FETCH PAST PARAMETERS
  // -----------------------------
  const fetchPastParameters = async () => {
    if (!proteinId) return;
    try {
      const allEntries = await db.proteins.toArray();
      const prefix = `novel_${proteinId}_`;
      const matching = allEntries.filter((entry) => entry.id.startsWith(prefix));

      const parsed = matching
        .map((entry) => {
          const paramData = extractParamFromKey(entry.id);
          if (paramData) {
            return {
              id: entry.id,
              ...paramData,
              name: `iter:${paramData.iterations}, topK:${paramData.topK}, seedT:${paramData.seedProbThreshold}, iterT:${paramData.iterProbThreshold}, simT:${paramData.simSearchThreshold}, workers:${paramData.simSearchWorkers}`,
            };
          }
          return null;
        })
        .filter(Boolean);
      setPastParameters(parsed);
    } catch (err) {
      console.error('Error fetching past parameters (novel):', err);
    }
  };

  // -----------------------------
  // REDUX ON MOUNT
  // -----------------------------
  useEffect(() => {
    dispatch(fetchParameters());
    dispatch(userDetails());
  }, [dispatch]);

  // -----------------------------
  // MAIN FETCH DATA
  // -----------------------------
  const fetchData = async (forceRefresh = false) => {
    if (!proteinId) {
      setError('No data provided');
      return;
    }
    if (!cacheKey) {
      setError('No cacheKey available');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      // 1) Fetch past parameters to populate the dropdown
      await fetchPastParameters();

      // 2) If not forceRefresh, check cache
      if (!forceRefresh) {
        const existing = await db.proteins.get(cacheKey);
        if (existing) {
          console.log('Using cached novel drug data for:', cacheKey);
          setTableData(existing.data);
          setLoading(false);
          return;
        }
      }

      // 3) No cache or forced => call server
      console.log('Fetching novel drug data from server with =>', {
        iterations: derivedIterations,
        topK: derivedTopK,
        seedProbThreshold: derivedSeedT,
        iterProbThreshold: derivedIterT,
        simSearchThreshold: derivedSimT,
        simSearchWorkers: derivedWorkers,
      });

      // Check if the relation is found
      const relationsResponse = await axios.get(`${BACKEND_URL}/relations/${proteinId}`);
      if (!relationsResponse.data.found) {
        setError(`Unable to predict novel drugs for target: ${proteinId}`);
        setLoading(false);
        return;
      }

      // Build the payload for the "predict-novelDrugs" route
      const payload = {
        target: proteinId,
        num_iterations: derivedIterations,
        top_k: derivedTopK,
        seed_prob_threshold: derivedSeedT,
        iter_prob_threshold: derivedIterT,
        sim_search_threshold: derivedSimT,
        sim_search_workers: derivedWorkers,
      };

      const predictResp = await fetch(`${BACKEND_URL}/auth/api/predict-novelDrugs`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });
      console.log(predictResp)
      if (!predictResp.ok) {
        const errorResp = await predictResp.json();
        console.log(errorResp)
        throw new Error(errorResp.error || `HTTP error! Status: ${predictResp.status}`);
      }

      const { predTempResult, predAdmet_result } = await predictResp.json();

      // Build "repurposeableDrugs" array
      const predictDrugsData = predTempResult.map((item, idx) => ({
        ID: item.MCULE_ID,
        C_SMILES: item.C_SMILES,
        PRED: item.PRED,
      }));

      const admetData = predictDrugsData.reduce((acc, drug, idx) => {
        acc[drug.C_SMILES] = predAdmet_result[idx] || {};
        return acc;
      }, {});

      const repurposeableDrugsData = predictDrugsData.map((pItem) => ({
        id: pItem.ID || 'N/A',
        smiles: pItem.C_SMILES,
        cov: pItem.PRED,
        dock: 'N/A',
        admet: admetData[pItem.C_SMILES] || {},
        structure: 'View',
      }));

      // 4) Known Drugs
      let predictKnownDrugsData = [];
      try {
        const knownDrugsResp = await axios.get(`${BACKEND_URL}/relations/knownDrugs/${proteinId}`);
        if (knownDrugsResp.data.found) {
          const knownDrugsPayload = {
            s_list: knownDrugsResp.data.smilesList,
            num_cores: 2,
            target: proteinId,
            top_k: null,
            prob_threshold: 0,
          };
          const knownDrugsPrediction = await axios.post(
            `${BACKEND_URL}/auth/api/predict-initial-drugs`,
            knownDrugsPayload
          );
          const { predTempResult: knownDrugs, predAdmet_result: knownAdmet } =
            knownDrugsPrediction.data;

          predictKnownDrugsData = knownDrugs.map((item, index) => {
            const relatedDrug =
              knownDrugsResp.data.relatedDrugs.find((drug) => drug.smile === item.C_SMILES) || {};
            return {
              id: relatedDrug.drugId || item.DRUG__ID,
              smiles: item.C_SMILES,
              truncatedSmiles:
                item.C_SMILES.length > 10
                  ? `${item.C_SMILES.substring(0, 10)}...`
                  : item.C_SMILES,
              name: relatedDrug.name || 'Unknown',
              cov: item.PRED,
              dock: 'N/A',
              admet: knownAdmet[index] || {},
              structure: 'View',
            };
          });
        } else {
          console.warn(`No known drugs found for target: ${proteinId}`);
        }
      } catch (knownErr) {
        console.error('Error predicting known drugs:', knownErr);
        // Fallback to empty array
        predictKnownDrugsData = [];
      }

      const finalTable = {
        repurposeableDrugs: repurposeableDrugsData,
        KnownDrugs: predictKnownDrugsData,
      };
      setTableData(finalTable);

      // 5) Store in IndexedDB
      await db.proteins.put({
        id: cacheKey,
        name: `NovelDrugs for ${proteinId} (iter=${derivedIterations}, topK=${derivedTopK}, seedT=${derivedSeedT}, iterT=${derivedIterT}, simT=${derivedSimT}, workers=${derivedWorkers})`,
        data: finalTable,
        timestamp: Date.now(),
      });
      console.log(`Cached novel drug data at key: ${cacheKey}`);

      // Re-fetch to ensure new entry is listed in the dropdown
      await fetchPastParameters();
    } catch (err) {
      console.error('Error fetching or predicting novel drugs:', err);
      setError(`Error: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  // -----------------------------
  // USEEFFECT => Fetch Data
  // -----------------------------
  useEffect(() => {
    if (proteinId) {
      fetchData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    proteinId,
    derivedIterations,
    derivedTopK,
    derivedSeedT,
    derivedIterT,
    derivedSimT,
    derivedWorkers,
    selectedParameter, // to re-fetch if user changes the dropdown
  ]);

  // -----------------------------
  // DOCKING
  // -----------------------------
  const handleDockSmiles = async (smiles, selectedPdbId) => {
    if (!tableData) return;

    // Mark those molecules as "Docking"
    const combined = [...tableData.repurposeableDrugs, ...tableData.KnownDrugs];
    const dockingArray = combined.map((item) =>
      smiles.includes(item.smiles) ? { ...item, dock: 'Docking' } : item
    );
    const updatedRepurpose = dockingArray.filter((x) =>
      tableData.repurposeableDrugs.some((rd) => rd.id === x.id)
    );
    const updatedKnown = dockingArray.filter((x) =>
      tableData.KnownDrugs.some((kd) => kd.id === x.id)
    );

    setTableData({ repurposeableDrugs: updatedRepurpose, KnownDrugs: updatedKnown });

    // Dock parameters
    const exhaustiveness = dockParameters.exhaustiveness || 8;
    const cpus = dockParameters.cpus || 2;

    const Dock = {
      uniprot_acc: proteinId,
      pdb_id: selectedPdbId,
      exhaustiveness,
      cpus,
      smiles_list: smiles,
    };

    const payload = {
      email: UserData.email,
      conversationID: conv_ID,
      queryID: props.Query_ID,
      DockValues: Dock,
    };

    try {
      const response = await fetch(`${BACKEND_URL}/auth/save-dock-result`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });
      const result = await response.json();

      if (!result?.results) {
        console.error('Docking error:', result);
        throw new Error(result?.message || 'Docking error');
      }

      // Prepare docking data
      const dockingData = smiles.map((s) => {
        const filtered = result.results.filter((r) => r.smiles === s);
        return {
          smiles: s,
          dockData: filtered.map((res) => ({
            model: res.model,
            'binding affinity': res['binding affinity'],
            'rmsd l.b.': res['rmsd l.b.'],
            'rmsd u.b.': res['rmsd u.b.'],
          })),
        };
      });

      // Merge docking result
      const updatedDocking = dockingArray.map((item) => {
        const match = dockingData.find((dd) => dd.smiles === item.smiles);
        if (match) {
          return { ...item, dock: 'View', dockResult: match.dockData };
        }
        return item;
      });

      const finalRepurpose = updatedDocking.filter((x) => updatedRepurpose.some((r) => r.id === x.id));
      const finalKnown = updatedDocking.filter((x) => updatedKnown.some((k) => k.id === x.id));

      const finalTableData = { repurposeableDrugs: finalRepurpose, KnownDrugs: finalKnown };
      setTableData(finalTableData);

      // Update cache
      if (cacheKey) {
        await db.proteins.put({
          id: cacheKey,
          name: `NovelDrugs for ${proteinId} (iter=${derivedIterations}, topK=${derivedTopK}, seedT=${derivedSeedT}, iterT=${derivedIterT}, simT=${derivedSimT}, workers=${derivedWorkers})`,
          data: finalTableData,
          timestamp: Date.now(),
        });
        console.log('Cache updated with docking results');
      }
    } catch (err) {
      console.error('Error docking novel drugs:', err);
    } finally {
      setDockSmiles(null);
    }
  };

  // -----------------------------
  // SAVE EDITED SMILES
  // -----------------------------
  const handleSaveSmiles = async (editedMoleculesArray = []) => {
    if (!Array.isArray(editedMoleculesArray) || editedMoleculesArray.length === 0) {
      setEditSmiles(null);
      return;
    }

    const combined = [...tableData.repurposeableDrugs, ...tableData.KnownDrugs];
    const updatedCombined = combined.map((item) => {
      const match = editedMoleculesArray.find((m) => m.initialSmiles === item.smiles);
      if (match) {
        const molecule = OCL.Molecule.fromSmiles(match.currentSmiles);
        const svg = molecule.toSVG(300, 300);
        return {
          ...item,
          smiles: match.currentSmiles,
          truncatedSmiles:
            match.currentSmiles.length > 10
              ? `${match.currentSmiles.substring(0, 10)}...`
              : match.currentSmiles,
          editedMoleculeSvg: btoa(svg),
        };
      }
      return item;
    });

    const updatedRepurpose = updatedCombined.filter((x) =>
      tableData.repurposeableDrugs.some((r) => r.id === x.id)
    );
    const updatedKnown = updatedCombined.filter((x) =>
      tableData.KnownDrugs.some((k) => k.id === x.id)
    );

    const finalTable = { repurposeableDrugs: updatedRepurpose, KnownDrugs: updatedKnown };
    setTableData(finalTable);

    // Prepare payload
    const moleculeArray = editedMoleculesArray.map(({ initialSmiles, currentSmiles }) => {
      const orig = combined.find((c) => c.smiles === initialSmiles);
      return {
        sourceMoleculeID: orig?.id || '',
        sourceMoleculeSMILES: initialSmiles,
        editedMoleculeSMILES: currentSmiles,
      };
    });

    try {
      const payload = {
        email: UserData.email,
        conversationID: conv_ID,
        queryName: `Predicted Novel drugs for ${proteinId}`,
        queryID: props.Query_ID,
        proteinID: proteinId,
        molecules: moleculeArray,
      };

      const resp = await fetch(`${BACKEND_URL}/auth/save-edited-molecule`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });
      const result = await resp.json();
      if (!result.status) {
        console.error('Error saving edited molecules:', result.message);
      }

      // Update cache
      if (cacheKey) {
        await db.proteins.put({
          id: cacheKey,
          name: `NovelDrugs for ${proteinId} (iter=${derivedIterations}, topK=${derivedTopK}, seedT=${derivedSeedT}, iterT=${derivedIterT}, simT=${derivedSimT}, workers=${derivedWorkers})`,
          data: finalTable,
          timestamp: Date.now(),
        });
        console.log('Cache updated with edited molecules');
      }
    } catch (err) {
      console.error('Error saving edited SMILES:', err);
    }

    setEditSmiles(null);
  };

  // -----------------------------
  // RENDER
  // -----------------------------
  return (
    <div className="p-4">
      {error ? (
        <div className="text-xl font-bold mb-4 text-gray-400">
          {error} <span className="pl-2 text-[#494949]">{proteinId}</span>
        </div>
      ) : (
        <>
          {/* Dropdown for Past Parameters */}
          {pastParameters.length > 0 && (
            <div className="mb-4">
              <label
                htmlFor="past-parameters"
                className="block text-sm font-medium text-gray-700"
              >
                Load Previous Parameters:
              </label>
              <select
                id="past-parameters"
                name="past-parameters"
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300
                           focus:outline-none focus:ring-indigo-500
                           focus:border-indigo-500 sm:text-sm rounded-md"
                value={selectedParameter ? selectedParameter.id : ''}
                onChange={(e) => {
                  const chosen = pastParameters.find((p) => p.id === e.target.value);
                  if (chosen) setSelectedParameter(chosen);
                  else setSelectedParameter(null);
                }}
              >
                <option value="">-- Use New Settings --</option>
                {pastParameters.map((param) => (
                  <option key={param.id} value={param.id}>
                    {param.name}
                  </option>
                ))}
              </select>
            </div>
          )}

          {/* Force refresh link */}
          {/* {pastParameters.length > 0 && (
            <div className="mb-4">
              <button
                className="text-blue-500 underline cursor-pointer"
                onClick={() => fetchData(true)}
                disabled={loading}
              >
                {loading ? 'Refreshing...' : 'Refresh with New Settings'}
              </button>
            </div>
          )} */}

          {/* Loading Indicator */}
          {loading ? (
            <div className="text-xl font-bold mb-4 text-gray-300">Loading....</div>
          ) : (
            <>
              <h2 className="text-xl font-bold mb-4 text-[#494949]">
                Predicted Novel Drugs for {proteinId} ({proteinName})
              </h2>

              {tableData ? (
                <MergeTableView
                  argument={props}
                  data={tableData}
                  selectable={true}
                  onAdmetClick={(admet, drugName) => {
                    setSelectedAdmet(admet);
                    setSelectedDrugName(drugName);
                  }}
                  onEditSmiles={(smiles) => setEditSmiles(smiles)}
                  onDockSmiles={(smiles) => setDockSmiles(smiles)}
                  selectedPdbId={null}
                  isRowClickable={false}
                  name="novel"
                />
              ) : (
                <div className="text-xl font-bold mb-4 text-gray-300">
                  No data available
                </div>
              )}
            </>
          )}
        </>
      )}

      {/* Popups */}
      {selectedAdmet && (
        <AdmetPopup
          admet={selectedAdmet}
          drugName={selectedDrugName}
          onClose={() => setSelectedAdmet(null)}
        />
      )}
      {editSmiles && (
        <MoleculeEditorPopup
          smilesList={Array.isArray(editSmiles) ? editSmiles : [editSmiles]}
          onSaveAll={handleSaveSmiles}
          onClose={() => setEditSmiles(null)}
        />
      )}
      {dockSmiles && (
        <DockingPopup
          proteinID={proteinId}
          smilesList={Array.isArray(dockSmiles) ? dockSmiles : [dockSmiles]}
          onDock={(smiles, pdbId) => {
            handleDockSmiles(smiles, pdbId);
          }}
          onClose={() => setDockSmiles(null)}
        />
      )}
    </div>
  );
};

export default PredictNovelDrugsInterface;