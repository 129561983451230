import { v4 as uuidv4 } from 'uuid';

const newConv_ID = uuidv4().replace(/-/g, '').trim();

const initialState = {
  Diseases: [],
  Proteins: [],
  ProteinSeq: [],
  Drugs: [],
  UserDetails: [],
  Relations: [],
  conversations: [],
  Allconversations: [],
  conv_ID: newConv_ID,
  queries: [],
  sessionQueryID: null,
  parameters: {
    predictRepurpose: {
      topK: 100,
      probThreshold: 0.6,
    },
    predictInitialDrugs: {
      topK: 100,
      probThreshold: 0.6,
    },
    dock: {
      exhaustiveness: 8,
      cpus: 2,
    },
    predictNovelDrugs: {
      iterations: 1,
      topK: 100,
      seedProbThreshold: 0.75,
      iterProbThreshold: 0.7,
      simSearchThreshold: 0.7,
      simSearchWorkers: 1,
    },
  },
  selectedConversation: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // All Diseases
    case 'FETCH_DISEASES':
      return {
        ...state,
        Diseases: action.payload,
      };

    // All Proteins
    case 'FETCH_PROTEINS':
      return {
        ...state,
        Proteins: action.payload,
      };

    // All Protein sequences
    case 'FETCH_PROTEINSEQ':
      return {
        ...state,
        ProteinSeq: action.payload,
      };

    // All Drugs
    case 'FETCH_DRUGS':
      return {
        ...state,
        Drugs: action.payload,
      };

    // User Data
    case "USER_DATA":
      return {
        ...state, UserDetails: action.payload
      };

    // Associated relations data
    case "FETCH_RELATIONS":
      return {
        ...state,
        Relations: action.payload
      };
    case 'FETCH_CONVERSATION':
      return { ...state, conversations: action.payload };

    case 'SAVE_CONVERSATION':
      return { ...state, conversations: action.payload };

    case 'FETCH_ALLCONVERSATIONS':
      return { ...state, Allconversations: action.payload };

    case 'UPDATE_CONV_ID':
      return { ...state, conv_ID: action.payload };

    case 'SET_QUERIES':
      return { ...state, queries: action.payload, };

    case 'SESSION_QUERYID':
      return { ...state, sessionQueryID: action.payload };

    // case 'SET_PREDICT_REPURPOSE':
    //   return {
    //     ...state,
    //     predictRepurpose: {
    //       ...state.predictRepurpose,
    //       topK: action.payload.topK,
    //       probThreshold: action.payload.probThreshold,
    //     },
    //   };
    // case 'SET_PREDICT_INITIAL_DRUGS':
    //   return {
    //     ...state,
    //     predictInitialDrugs: {
    //       ...state.predictInitialDrugs,
    //       topK: action.payload.topK,
    //       probThreshold: action.payload.probThreshold,
    //     },
    //   };

    // case 'SET_DOCK':
    //   return {
    //     ...state,
    //     dock: {
    //       ...state.dock,
    //       ...action.payload,
    //     },
    //   };


    // case 'SET_PREDICT_NOVEL_DRUGS':
    //   return {
    //     ...state, noverDrugs: { ...action.payload }
    //   }

    case 'SET_PARAMETERS':
      console.log("SET_PARAMETERS payload: ", action.payload); // For debugging
      return {
        ...state,
        // parameters: action.payload, // Update parameters in the state
        parameters: {
          ...state.parameters,
          // Merge each sub-field carefully, in case the server only sends partial updates
          predictRepurpose: {
            ...state.parameters.predictRepurpose,
            ...(action.payload.predictRepurpose || {}), 
          },
          predictInitialDrugs: {
            ...state.parameters.predictInitialDrugs,
            ...(action.payload.predictInitialDrugs || {}),
          },
          dock: {
            ...state.parameters.dock,
            ...(action.payload.dock || {}),
          },
          predictNovelDrugs: {
            ...state.parameters.predictNovelDrugs,
            ...(action.payload.predictNovelDrugs || {}),
          }
        }
      };

    case 'SET_SELECTED_CONVERSATION':
      return {
        ...state,
        selectedConversation: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;