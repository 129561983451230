import axios from 'axios';
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const fetchDiseases = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${BACKEND_URL}/disease`);
      // console.log('FetchDiseases', response.data)
      dispatch({ type: 'FETCH_DISEASES', payload: response.data });
    } catch (error) {
      console.error('Error fetching Diseases:', error);
      dispatch({ type: 'FETCH_DISEASES_FAILURE', payload: error.message });
    }
  };
};

export const fetchProteins = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${BACKEND_URL}/protein/id`);
      // console.log('FetchProteins', response.data)
      dispatch({ type: 'FETCH_PROTEINS', payload: response.data });
    } catch (error) {
      console.error('Error fetching Proteins:', error);
      dispatch({ type: 'FETCH_PROTEINS_FAILURE', payload: error.message });
    }
  };
};

export const fetchProteinSequence = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${BACKEND_URL}/protein/sequence`);
      // console.log('FetchProteinseq', response.data)
      dispatch({ type: 'FETCH_PROTEINSEQ', payload: response.data });
    } catch (error) {
      console.error('Error fetching Proteinseq:', error);
      dispatch({ type: 'FETCH_PROTEINSEQ_FAILURE', payload: error.message });
    }
  };
};

export const fetchDrugs = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${BACKEND_URL}/drug`);
      // console.log('FetchDrugs', response.data)
      dispatch({ type: 'FETCH_DRUGS', payload: response.data });
    } catch (error) {
      console.error('Error fetching Drugs:', error);
      dispatch({ type: 'FETCH_DRUGS_FAILURE', payload: error.message });
    }
  };
};

// export const userDetails = () => {
//   return async (dispatch) => {
//       try {
//           const response = await axios.get(`${BACKEND_URL}/auth/verify`, { withCredentials: true });
//           // console.log(response.data); // Log the response data
//           dispatch({ type: 'USER_DATA', payload: response.data.user});
//       } catch (error) {
//           console.error('Error fetching user details:', error);
//           dispatch({ type: 'USER_DETAILS_FAILURE', payload: error.message });
//       }
//   };
// };
export const userDetails = (token) => {
  return async (dispatch) => {
    try {
      // console.log("Token: ", token); // Log the token
      const response = await axios.get(`${BACKEND_URL}/auth/verify`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        withCredentials: true,
      });
      // console.log(response.data); // Log the response data
      dispatch({ type: 'USER_DATA', payload: response.data.user });
    } catch (error) {
      console.error('Error fetching user details:', error);
      dispatch({ type: 'USER_DETAILS_FAILURE', payload: error.message });
    }
  };
};

export const fetchRelations = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${BACKEND_URL}/relations`);
      // console.log(response)
      dispatch({ type: 'FETCH_RELATIONS', payload: response.data });
    } catch (error) {
      console.error('Error fetching Drugs:', error);
      dispatch({ type: 'FETCH_RELATIONS_FAILURE', payload: error.message });
    }
  };
};

export const fetchConversation = (conv_ID) => async (dispatch) => {
  try {
    const response = await axios.get(`${BACKEND_URL}/auth/get-conversation/${conv_ID}`);
    dispatch({ type: 'FETCH_CONVERSATION', payload: response.data });
    // console.log(response.data)
  } catch (error) {
    dispatch({ type: 'FETCH_CONVERSATION_FAILURE', payload: error.message });
  }
};

export const fetchALLConversations = () => async (dispatch) => {
  try {
    const response = await axios.get(`${BACKEND_URL}/auth/get-conversations`);
    dispatch({ type: 'FETCH_ALLCONVERSATIONS', payload: response.data.conversations });
    // console.log(response.data.conversations)
  } catch (error) {
    dispatch({ type: 'FETCH_CONVERSATION_FAILURE', payload: error.message });
  }
};

export const saveConversation = (conversation) => async (dispatch) => {
  try {
    const response = await axios.post(`${BACKEND_URL}/auth/save-conversation`, { conversations: conversation }, { withCredentials: true });
    dispatch({ type: 'SAVE_CONVERSATION', payload: response.data });
  } catch (error) {
    dispatch({ type: 'SAVE_CONVERSATION_FAILURE', payload: error.message });
  }
};

export const updateConvID = (conv_ID) => (
  {
    type: 'UPDATE_CONV_ID',
    payload: conv_ID,
  }
);

export const setQueries = (queries) => (dispatch) => {

  dispatch({
    type: 'SET_QUERIES',
    payload: queries,
  });
}

export const sessionQueryID = (queryID) => ({
  type: 'SESSION_QUERYID',
  payload: queryID,
});

export const fetchParameters = () => {
  return async (dispatch) => {
      try {
          const response = await axios.get(`${BACKEND_URL}/auth/get-parameters`); // Adjust the URL based on your server configuration
          // console.log('Fetched parameters:', response.data);
          if (response.data.status) {
            // Check if parameters exist in the response
            if (response.data.parameters) {
                dispatch({
                    type: 'SET_PARAMETERS',
                    payload: response.data.parameters,
                });
            } else {
                // Dispatch initial parameters if none are found
                dispatch({
                    type: 'SET_PARAMETERS',
                    payload: {
                        predictRepurpose: {
                            topK: 100,
                            probThreshold: 0.6,
                        },
                        predictInitialDrugs: {
                            topK: 100,
                            probThreshold: 0.6,
                        },
                        dock: {
                            exhaustiveness: 8,
                            cpus: 2,
                        },
                        novelDrugs: {
                            iterations: 1,
                            topK: 100,
                            seedProbThreshold: 0.8,
                            iterProbThreshold: 0.8,
                            simSearchThreshold: 0.8,
                            simSearchWorkers: 1,
                        },
                    },
                });
            }
        }
    } catch (error) {
        console.error('Error fetching parameters:', error);
        // Handle error if needed
    }
  };
};

export const setSelectedConversation = (conversation) => {
  // console.log('Dispatching selected conversation:', conversation); // Console log for debugging
  return {
    type: 'SET_SELECTED_CONVERSATION',
    payload: conversation,
  };
};
