import React, { useState, useEffect, useMemo } from 'react';
import AdmetPopup from './AdmetPopup';
import MoleculeEditorPopup from './MoleculeEditorPopup';
import OCL from 'openchemlib';
import { useDispatch, useSelector } from 'react-redux';
import { fetchParameters, userDetails } from '../../redux/Actions/action';
import axios from 'axios';
import DockingPopup from './DockingPopup';
import MergeTableView from './MergeTableView';
import db from '../../db'; // Dexie instance for IndexedDB

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const PredictDrugInterface = (data) => {
  // -----------------------------
  // Component State Variables
  // -----------------------------
  const [tableData, setTableData] = useState({ repurposeableDrugs: [], KnownDrugs: [] });
  const [selectedAdmet, setSelectedAdmet] = useState(null);
  const [selectedDrugName, setSelectedDrugName] = useState('');
  const [editSmiles, setEditSmiles] = useState(null);
  const [dockSmiles, setDockSmiles] = useState(null);
  const [error, setError] = useState(null);
  const [selectedPdbId, setSelectedPdbId] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentParameters, setCurrentParameters] = useState(null);

  // For cached parameter selection via dropdown:
  const [pastParameters, setPastParameters] = useState([]);
  const [selectedParameter, setSelectedParameter] = useState(null);

  // -----------------------------
  const Repurpose = "Repurpose"
  
  // Redux State & Dispatch
  // -----------------------------
  const dispatch = useDispatch();
  const conv_ID = useSelector((state) => state.conv_ID);
  const UserData = useSelector((state) => state.UserDetails);
  const repurposeParameters = useSelector((state) => state.parameters.predictRepurpose) || {};
  const dockParameters = useSelector((state) => state.parameters.dock);

  // -----------------------------
  // On mount, fetch redux parameters & user details
  // -----------------------------
  useEffect(() => {
    dispatch(fetchParameters());
    dispatch(userDetails());
  }, [dispatch]);

  // -----------------------------
  // Compute protein ID & current settings
  // -----------------------------
  const proteinId = useMemo(() => data?.data?.PROTEIN, [data]);

  // Global settings from redux (defaults used if not provided)
  const topk = repurposeParameters.topK || 100;
  const probThreshold = repurposeParameters.probThreshold || 0.6;

  // -----------------------------
  // Persist/Load Selected Cached Parameter from Local Storage
  // -----------------------------
  useEffect(() => {
    if (!proteinId) return;
    const storageKey = `predictDrug_selectedParam_${proteinId}`;
    const stored = localStorage.getItem(storageKey);
    if (stored) {
      try {
        const parsed = JSON.parse(stored);
        setSelectedParameter(parsed);
      } catch (err) {
        console.error('Error parsing stored parameter:', err);
      }
    }
  }, [proteinId]);

  useEffect(() => {
    if (!proteinId) return;
    const storageKey = `predictDrug_selectedParam_${proteinId}`;
    if (selectedParameter) {
      localStorage.setItem(storageKey, JSON.stringify(selectedParameter));
    } else {
      localStorage.removeItem(storageKey);
    }
  }, [selectedParameter, proteinId]);

  // -----------------------------
  // Derived Prediction Parameters
  // -----------------------------
  // If a cached parameter is selected via dropdown then use it; otherwise use redux values.
  const derivedTopK = selectedParameter?.topK ?? topk;
  const derivedProbT = selectedParameter?.probThreshold ?? probThreshold;

  // -----------------------------
  // Construct Cache Key
  // -----------------------------
  // Format: repurpose_<proteinId>_topK<derivedTopK>_probT<derivedProbT>
  const cacheKey = useMemo(() => {
    if (!proteinId) return null;
    return `repurpose_${proteinId}_topK${derivedTopK}_probT${derivedProbT}`;
  }, [proteinId, derivedTopK, derivedProbT]);

  // -----------------------------
  // Utility: Extract Parameters from Cache Key
  // -----------------------------
  const extractParamFromKey = (key) => {
    const regex = /^repurpose_\w+_topK(\d+)_probT([\d.]+)$/;
    const match = key.match(regex);
    if (!match) return null;
    return {
      topK: parseInt(match[1], 10),
      probThreshold: parseFloat(match[2])
    };
  };

  // -----------------------------
  // Fetch Past Parameter Sets from IndexedDB (for Dropdown)
  // -----------------------------
  const fetchPastParameters = async () => {
    if (!proteinId) return;
    try {
      const allEntries = await db.proteins.toArray();
      const prefix = `repurpose_${proteinId}_`;
      const matching = allEntries.filter((entry) => entry.id.startsWith(prefix));
      const parsed = matching.map((entry) => {
        const params = extractParamFromKey(entry.id);
        if (params) {
          return {
            id: entry.id,
            ...params,
            name: `TopK: ${params.topK}, ProbT: ${params.probThreshold}`
          };
        }
        return null;
      }).filter(Boolean);
      setPastParameters(parsed);
    } catch (err) {
      console.error('Error fetching past parameters:', err);
    }
  };

  // -----------------------------
  // Main Data Fetch (with Caching)
  // -----------------------------
  const fetchData = async (forceRefresh = false) => {
    if (!proteinId) {
      setError('No data provided');
      return;
    }
    if (!cacheKey) {
      setError('No cache key available - missing protein ID');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      // Always update the dropdown options
      await fetchPastParameters();

      // Check cache if not forcing refresh
      if (!forceRefresh) {
        const cachedEntry = await db.proteins.get(cacheKey);
        if (cachedEntry) {
          console.log('Using cached repurpose data for:', cacheKey);
          setTableData(cachedEntry.data);
          setCurrentParameters(extractParamFromKey(cacheKey));
          setLoading(false);
          return;
        }
      }

      // If no valid cache or forcing a refresh, fetch from server.
      const payload = {
        target: proteinId,
        top_k: derivedTopK,
        prob_threshold: derivedProbT,
      };

      // Check for target relations
      const relationsResponse = await axios.get(`${BACKEND_URL}/relations/${proteinId}`);
      if (!relationsResponse.data.found) {
        setError(`Unable to predict drugs for the given target `);
        setLoading(false);
        return;
      }

      // Call predict-repurpose endpoint
      const predictResponse = await axios.post(`${BACKEND_URL}/auth/api/predict-repurpose`, payload);
      const { predTempResult, predAdmet_result } = predictResponse.data;

      // Process repurposeable drugs
      const repurposeableDrugsData = await Promise.allSettled(
        predTempResult.map(async (item, index) => {
          const baseDrug = {
            id: item.DRUG__ID,
            smiles: item.C_SMILES,
            truncatedSmiles: item.C_SMILES.length > 10 ? `${item.C_SMILES.substring(0, 10)}...` : item.C_SMILES,
            name: 'Loading...',
            cov: item.PRED,
            dock: 'N/A',
            admet: predAdmet_result[index] || {},
            structure: 'View',
          };
          try {
            const drugResp = await axios.get(`${BACKEND_URL}/drug/${item.DRUG__ID}`);
            return { ...baseDrug, name: drugResp.data.NAME || 'N/A' };
          } catch {
            return { ...baseDrug, name: 'N/A' };
          }
        })
      ).then(results => results.map(r => (r.status === 'fulfilled' ? r.value : {})))
        .catch(() => []);

      // Get known drugs predictions
      let predictKnownDrugsData = [];
      try {
        const knownDrugsResponse = await axios.get(`${BACKEND_URL}/relations/knownDrugs/${proteinId}`);
        if (!knownDrugsResponse.data.found) {
          setError(`Unable to predict known drugs for the given target ${proteinId}`);
          setLoading(false);
          return;
        }

        const knownDrugsPayload = {
          s_list: knownDrugsResponse.data.smilesList,
          num_cores: 2,
          target: proteinId,
          top_k: null,
          prob_threshold: 0,
        };

        const knownDrugsPrediction = await axios.post(`${BACKEND_URL}/auth/api/predict-initial-drugs`, knownDrugsPayload);
        const { predTempResult: knownDrugs, predAdmet_result: knownAdmet } = knownDrugsPrediction.data;

        predictKnownDrugsData = knownDrugs.map((item, index) => {
          const relatedDrug = knownDrugsResponse.data.relatedDrugs.find((drug) => drug.smile === item.C_SMILES) || {};
          return {
            id: relatedDrug.drugId || item.DRUG__ID,
            smiles: item.C_SMILES,
            truncatedSmiles: item.C_SMILES.length > 10 ? `${item.C_SMILES.substring(0, 10)}...` : item.C_SMILES,
            name: relatedDrug.name || 'Unknown',
            cov: item.PRED,
            dock: 'N/A',
            admet: knownAdmet[index] || {},
            structure: 'View',
          };
        });
      } catch (err) {
        console.error('Error predicting known drugs:', err);
        predictKnownDrugsData = [];
      }

      // Combine data sets
      const finalData = {
        repurposeableDrugs: repurposeableDrugsData,
        KnownDrugs: predictKnownDrugsData,
      };

      setTableData(finalData);
      setCurrentParameters({ topK: derivedTopK, probThreshold: derivedProbT });

      // Cache the new results in IndexedDB
      await db.proteins.put({
        id: cacheKey,
        name: `Repurpose predictions for ${proteinId} (topK=${derivedTopK}, probT=${derivedProbT})`,
        data: finalData,
        timestamp: Date.now(),
      });
      console.log('Cached new repurpose data at:', cacheKey);
      await fetchPastParameters(); // update dropdown options
    } catch (err) {
      console.error('Error fetching or caching predictions:', err);
      setError(`Error: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  // -----------------------------
  // Auto-Fetch on Mount / when protein or parameters change
  // -----------------------------
  useEffect(() => {
    if (proteinId) {
      fetchData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proteinId, derivedTopK, derivedProbT, selectedParameter]);

  // -----------------------------
  // Handle Docking (updates both repurposeable and known drugs)
  // -----------------------------
  const handleDockSmiles = async (smiles, pdbId) => {
    // Combine arrays for processing
    const combined = [
      ...tableData.repurposeableDrugs,
      ...tableData.KnownDrugs
    ];
    const updatedCombined = combined.map((item) =>
      smiles.includes(item.smiles) ? { ...item, dock: 'Docking' } : item
    );

    // Split back the data into repurposeable and known drugs (by matching IDs)
    const updatedRepurposeable = updatedCombined.filter((item) =>
      tableData.repurposeableDrugs.some((d) => d.id === item.id)
    );
    const updatedKnown = updatedCombined.filter((item) =>
      tableData.KnownDrugs.some((d) => d.id === item.id)
    );
    const updatedTable = {
      repurposeableDrugs: updatedRepurposeable,
      KnownDrugs: updatedKnown,
    };
    setTableData(updatedTable);

    const DockValues = {
      uniprot_acc: proteinId,
      pdb_id: pdbId,
      exhaustiveness: dockParameters.exhaustiveness || 8,
      cpus: dockParameters.cpus || 2,
      smiles_list: smiles,
    };

    const payload = {
      email: UserData.email,
      conversationID: conv_ID,
      queryID: data.Query_ID,
      DockValues,
    };

    try {
      const response = await fetch(`${BACKEND_URL}/auth/save-dock-result`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });
      const result = await response.json();

      const dockingData = smiles.map((s) => {
        const filtered = result.results.filter((r) => r.smiles === s);
        return {
          smiles: s,
          dockData: filtered.map((res) => ({
            model: res.model,
            'binding affinity': res['binding affinity'],
            'rmsd l.b.': res['rmsd l.b.'],
            'rmsd u.b.': res['rmsd u.b.'],
          })),
        };
      });

      const updatedWithDock = combined.map((item) => {
        const match = dockingData.find((d) => d.smiles === item.smiles);
        return match ? { ...item, dock: 'View', dockResult: match.dockData } : item;
      });

      const finalRepurposeable = updatedWithDock.filter((item) =>
        tableData.repurposeableDrugs.some((d) => d.id === item.id)
      );
      const finalKnown = updatedWithDock.filter((item) =>
        tableData.KnownDrugs.some((d) => d.id === item.id)
      );
      const finalTable = {
        repurposeableDrugs: finalRepurposeable,
        KnownDrugs: finalKnown,
      };
      setTableData(finalTable);

      // Update cache with new docking info
      if (cacheKey) {
        await db.proteins.put({
          id: cacheKey,
          name: `Repurpose predictions for ${proteinId} (topK=${derivedTopK}, probT=${derivedProbT})`,
          data: finalTable,
          timestamp: Date.now(),
        });
        console.log('Updated docking data cached at:', cacheKey);
      }
    } catch (err) {
      console.error('Error during docking:', err);
    }
    setDockSmiles(null);
  };

  // -----------------------------
  // Handle Save Edited SMILES
  // -----------------------------
  const handleSaveSmiles = async (editedMoleculesArray = []) => {
    if (!Array.isArray(editedMoleculesArray) || editedMoleculesArray.length === 0) {
      setEditSmiles(null);
      return;
    }
    // Update the drugs arrays with edited molecules
    const updateMolecules = (arr) =>
      arr.map((item) => {
        const edited = editedMoleculesArray.find((mol) => mol.initialSmiles === item.smiles);
        if (edited) {
          const molecule = OCL.Molecule.fromSmiles(edited.currentSmiles);
          const svg = molecule.toSVG(300, 300);
          return {
            ...item,
            smiles: edited.currentSmiles,
            truncatedSmiles:
              edited.currentSmiles.length > 10
                ? `${edited.currentSmiles.substring(0, 10)}...`
                : edited.currentSmiles,
            editedMoleculeSvg: btoa(svg),
          };
        }
        return item;
      });

    const updatedRepurposeable = updateMolecules(tableData.repurposeableDrugs);
    const updatedKnown = updateMolecules(tableData.KnownDrugs);
    const updatedTableData = {
      repurposeableDrugs: updatedRepurposeable,
      KnownDrugs: updatedKnown,
    };
    setTableData(updatedTableData);

    // Prepare payload for saving
    const moleculeArray = editedMoleculesArray.map(({ initialSmiles, currentSmiles }) => {
      const original = [...updatedRepurposeable, ...updatedKnown].find(
        (item) => item.smiles === currentSmiles
      );
      return {
        sourceMoleculeID: original?.id || '',
        sourceMoleculeSMILES: initialSmiles,
        editedMoleculeSMILES: currentSmiles,
      };
    });

    try {
      const payload = {
        email: UserData.email,
        conversationID: conv_ID,
        queryName: `Predicted drugs for ${proteinId}`,
        queryID: data.Query_ID,
        proteinID: proteinId,
        molecules: moleculeArray,
      };
      const response = await fetch(`${BACKEND_URL}/auth/save-edited-molecule`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });
      const result = await response.json();
      if (result.status) {
        console.log('Molecule saved successfully');
      } else {
        console.error('Error saving molecule:', result.message);
      }
      // Update cache with edited molecules
      if (cacheKey) {
        await db.proteins.put({
          id: cacheKey,
          name: `Repurpose predictions for ${proteinId} (topK=${derivedTopK}, probT=${derivedProbT})`,
          data: updatedTableData,
          timestamp: Date.now(),
        });
        console.log('Cache updated with edited molecules');
      }
    } catch (err) {
      console.error('Error saving edited molecules:', err);
    }
    setEditSmiles(null);
  };

  // -----------------------------
  // Render the Component
  // -----------------------------
  return (
    <>
      <div className="p-4">
        {error ? (
          <div className="text-xl font-bold mb-4 text-gray-400">
            {error} <span className="pl-2 text-[#494949]">{proteinId}</span>
          </div>
        ) : (
          <>
            {/* Parameter Dropdown for Cached Settings */}
            {pastParameters.length > 0 && (
              <div className="mb-4">
                <label htmlFor="past-parameters" className="block text-sm font-medium text-gray-700">
                  Load Previous Parameters:
                </label>
                <select
                  id="past-parameters"
                  name="past-parameters"
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300
                             focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  value={selectedParameter ? selectedParameter.id : ''}
                  onChange={(e) => {
                    const chosen = pastParameters.find((p) => p.id === e.target.value);
                    if (chosen) setSelectedParameter(chosen);
                    else setSelectedParameter(null);
                  }}
                >
                  <option value="">-- Use New Settings --</option>
                  {pastParameters.map((param) => (
                    <option key={param.id} value={param.id}>
                      {param.name}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {/* Loading Indicator / Data Table */}
            {loading ? (
              <div className="text-xl font-bold mb-4 text-gray-300">Loading....</div>
            ) : (
              <>
                <h2 className="text-xl font-bold text-[#494949]">
                  Predicted drugs for {proteinId} ({data.data.NAME})
                </h2>
                {tableData && (tableData.repurposeableDrugs.length > 0 || tableData.KnownDrugs.length > 0) ? (
                  <MergeTableView
                    argument={data}
                    data={tableData}
                    selectable={true}
                    onAdmetClick={(admet, drugName) => {
                      setSelectedAdmet(admet);
                      setSelectedDrugName(drugName);
                    }}
                    onEditSmiles={(smiles) => setEditSmiles(smiles)}
                    onDockSmiles={(smiles) => setDockSmiles(smiles)}
                    selectedPdbId={selectedPdbId}
                    isRowClickable={true}
                    name={Repurpose}
                  />
                ) : (
                  <div className="text-xl font-bold mb-4 text-gray-300">No data available</div>
                )}
              </>
            )}
          </>
        )}
      </div>

      {/* Popups */}
      {selectedAdmet && (
        <AdmetPopup
          admet={selectedAdmet}
          drugName={selectedDrugName}
          onClose={() => setSelectedAdmet(null)}
        />
      )}

      {editSmiles && (
        <MoleculeEditorPopup
          smilesList={Array.isArray(editSmiles) ? editSmiles : [editSmiles]}
          onSaveAll={handleSaveSmiles}
          onClose={() => setEditSmiles(null)}
        />
      )}

      {dockSmiles && (
        <DockingPopup
          proteinID={proteinId}
          smilesList={Array.isArray(dockSmiles) ? dockSmiles : [dockSmiles]}
          onDock={(smiles, pdbID) => {
            setSelectedPdbId(pdbID);
            handleDockSmiles(smiles, pdbID);
          }}
          onClose={() => setDockSmiles(null)}
        />
      )}
    </>
  );
};

export default PredictDrugInterface;