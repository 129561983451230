import React, { useEffect, useRef, useState } from 'react'
import { Tab } from '@headlessui/react';
import Sdf from './Sdf';
import { useSelector } from 'react-redux';
import ProteinDetailsPopup from './ProteinDetailsPopup';
import DiseaseDetailsPopup from './DiseaseDetailsPopup';
import { HiOutlineClipboardDocument } from "react-icons/hi2";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { FiClipboard } from 'react-icons/fi';
import { handleCopyToClipboard } from '../Functions/clipboardUtils';

const DrugInterface = (data) => {
    // console.log(data)

    const [relatedProteins, setRelatedProteins] = useState([]);
    const [relatedDiseases, setRelatedDiseases] = useState([]);
    const [diseaseDetails, setDiseaseDetails] = useState({}); // To store fetched disease details
    const [proteinDetails, setProteinDetails] = useState({}); // To store fetched disease details

    const [proteinIdPopup, setProteinIdPopup] = useState(null);
    const [diseaseIdPopup, setDiseaseIdPopup] = useState(null);
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

    const [showAllProteins, setShowAllProteins] = useState({});


    const [open, setOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const scrollRef = useRef(null);

    const handleSnackbarClose = () => {
        setOpen(false);
    };

    const handleCopy = (smiles) => {
        handleCopyToClipboard(smiles, setSnackbarMessage, setOpen);
    };

    useEffect(() => {
        // Fetch proteins and diseases from the API based on the drug data
        const fetchAssociations = async () => {
            try {
                const response = await fetch(`${BACKEND_URL}/relations/associations?drug=${data.data.DRUG}`);
                const result = await response.json();
                // console.log(result)
                setRelatedProteins(result.proteins);
                setRelatedDiseases(result.diseases);
            } catch (error) {
                console.error('Error fetching associations:', error);
            }
        };

        if (data.data.DRUG) {
            fetchAssociations();
        }
    }, [data.data.DRUG]);

    // Fetch disease details for each disease TAIL
    useEffect(() => {
        const fetchDiseaseDetails = async (diseaseId) => {
            try {
                const response = await fetch(`${BACKEND_URL}/disease/${diseaseId}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch disease details');
                }
                const details = await response.json();
                setDiseaseDetails(prevDetails => ({ ...prevDetails, [diseaseId]: details }));
            } catch (error) {
                console.error(`Error fetching details for ${diseaseId}:`, error);
            }
        };

        relatedDiseases.forEach(disease => {
            if (!diseaseDetails[disease.TAIL]) {
                fetchDiseaseDetails(disease.TAIL);
            }
        });
    }, [relatedDiseases, diseaseDetails]);

    // Fetch protein details for each protein TAIL
    useEffect(() => {
        const fetchProteinDetails = async (proteinId) => {
            try {
                const response = await fetch(`${BACKEND_URL}/protein/${proteinId}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch protein details');
                }
                const details = await response.json();
                setProteinDetails(prevDetails => ({ ...prevDetails, [proteinId]: details }));
            } catch (error) {
                console.error(`Error fetching details for ${proteinId}:`, error);
            }
        };

        relatedProteins.forEach(protein => {
            if (!proteinDetails[protein.TAIL]) {
                fetchProteinDetails(protein.TAIL);
            }
        });
    }, [relatedProteins, proteinDetails]);

    const handleClosePopup = () => {
        setProteinIdPopup(null);
        setDiseaseIdPopup(null);
    };

    const handleHorizontalScroll = (event) => {
        if (scrollRef.current) {
            scrollRef.current.scrollLeft += event.deltaY; // Adjust scrollLeft by the vertical scroll delta
        }
    };

    // Handle Load More / collapse toggle
    const toggleShowAll = (proteinTail) => {
        setShowAllProteins((prev) => ({
            ...prev,
            [proteinTail]: !prev[proteinTail], // Toggle visibility for the specific protein
        }));
    };

    // const tabs = ['Highlights', 'Structure', 'Graph Context', 'Diseases', 'Properties', 'Proteins'];
    const tabs = ['Highlights', 'Structure', 'Diseases', 'Properties', 'Proteins'];
    return (
        <>
            <div className=" p-1">
                <h2 className="text-xl font-bold mb-4 text-[#494949] break-all"> {`Drug ${data.data.DRUG}` || data?.query || 'Details'} ({data.data.NAME || ''})</h2>
                <Tab.Group>
                    <Tab.List className="flex p-1 space-x-1 rounded-xl">
                        {tabs.map((tab) => (
                            <Tab
                                key={tab}
                                className={({ selected }) =>
                                    `w-full py-2.5 text-sm leading-5 font-medium focus:outline-none ${selected ? 'text-[#FE4202] border-b-2 border-[#FE4202]' : 'hover:text-[#FE4202]'
                                    }`
                                }
                            >
                                {tab}
                            </Tab>
                        ))}
                    </Tab.List>

                    <Tab.Panels className="mt-2">
                        {tabs.includes('Highlights') && (
                            <Tab.Panel className="bg-white rounded-xl p-3 shadow">
                                <p><span className="font-semibold break-all">NAME: </span> {data?.data.NAME || "NA"}</p>
                                <div className='flex'>
                                    <span className="font-semibold">C_SMILES: </span>
                                    <p className="overflow-x-auto max-w-[400px] whitespace-nowrap custom-scrollbar px-2"
                                        ref={scrollRef}
                                        onWheel={handleHorizontalScroll}>
                                        <span >{data?.data?.C_SMILES || "NA"}</span>
                                    </p>
                                    {data?.data?.C_SMILES && (
                                        <FiClipboard
                                            className="ml-2 cursor-pointer text-gray-500 hover:text-gray-800"
                                            onClick={() => handleCopy(data?.data?.C_SMILES || "NA")}
                                            title="Copy SMILES"
                                        />
                                    )}
                                </div>
                                <p><span className="font-semibold">DRUGINCH: </span>{data?.data.DRUGINCH || "NA"}</p>
                                <p><span className="font-semibold">DRUGINCHKE: </span>{data?.data.DRUGINCHKE || "NA"}</p>
                                <p><span className="font-semibold">TYPE: </span>{data?.data.TYPE || "NA"}</p>
                            </Tab.Panel>
                        )}
                        {tabs.includes('Structure') && (
                            <Tab.Panel className="bg-white rounded-xl p-3 shadow">
                                <h3 className="text-lg font-medium">Structure</h3>
                                <Sdf sdf={data.data.SDF_STRUCTURE ? data.data.SDF_STRUCTURE.replace(/\\n/g, '\n') : null} />
                            </Tab.Panel>
                        )}
                        {/* {tabs.includes('Graph Context') && (
                            <Tab.Panel className="bg-white rounded-xl p-3 shadow">
                                <h3 className="text-lg font-medium">Graph Context</h3>
                                <p>{data.graphContext}</p>
                            </Tab.Panel>
                        )} */}
                        {tabs.includes('Diseases') && (
                            <Tab.Panel className="bg-white rounded-xl shadow min-h-[200px] max-h-[550px] overflow-y-auto custom-scrollbar">
                                {relatedDiseases.length === 0 ? (
                                    <p className=" text-gray-500 px-4 mt-4">No related diseases</p>
                                ) : (
                                    <table className="min-w-full bg-white border border-gray-200">
                                        <thead className='bg-gray-200 z-10'>
                                            <tr className="bg-gray-100 text-left">
                                                <th className="py-2 px-4 border-b">ID</th>
                                                <th className="py-2 px-4 border-b">NAME</th>
                                                <th className="py-2 px-4 border-b">TYPE</th>
                                                {/* <th className="py-2 px-4 border-b">DISEASE_SUPERGRP</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {relatedDiseases.map((disease, index) => {
                                                const details = diseaseDetails[disease.TAIL] || {};
                                                return (
                                                    <tr
                                                        key={index}
                                                        className="hover:bg-gray-50 cursor-pointer"
                                                        onClick={() => setDiseaseIdPopup(disease.TAIL)}
                                                    >
                                                        <td className="py-2 px-4 border-b"><span className="text-blue-600 underline">{disease.TAIL}</span></td>
                                                        <td className="py-2 px-4 border-b">{details.NAME || 'Loading...'}</td>
                                                        <td className="py-2 px-4 border-b">{details.TYPE || 'Loading...'}</td>
                                                        {/* <td className="py-2 px-4 border-b">{details.DISEASE_SUPERGRP?.$numberDouble || 'NaN'}</td> */}
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                )}
                            </Tab.Panel>
                        )}

                        {tabs.includes('Properties') && (
                            <Tab.Panel className="bg-white rounded-xl p-3 shadow overflow-x-auto max-h-[600px] overflow-y-auto custom-scrollbar">
                                <div className="list-disc pl-5">
                                    {[
                                        "PRODUCT_STAGE",
                                        "MolecularWeight",
                                        "LogP",
                                        "NumHAcceptors",
                                        "NumHDonors",
                                        "TPSA",
                                        "NumRotatableBonds",
                                        "LipinskiViolationCount"
                                    ].map((key, index) => {
                                        // Get the value from the data object
                                        const value = data.data[key];

                                        // Capitalize the first letter of PRODUCT_STAGE
                                        const formattedValue = key === 'PRODUCT_STAGE' && typeof value === 'string'
                                            ? value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
                                            : value;

                                        // Round specific numeric values
                                        const roundedValue = (typeof formattedValue === 'number' && (key === 'MolecularWeight' || key === 'LogP' || key === "TPSA"))
                                            ? formattedValue.toFixed(3)
                                            : formattedValue;

                                        return (
                                            <p key={index}>
                                                <span className='font-semibold'>{key}</span> : {roundedValue !== null && roundedValue !== undefined ? roundedValue.toString() : "NA"}
                                            </p>
                                        );
                                    })}
                                </div>
                            </Tab.Panel>
                        )}

                        {tabs.includes('Proteins') && (
                            <Tab.Panel className="bg-white rounded-xl shadow min-h-[200px] max-h-[550px] overflow-y-auto custom-scrollbar">
                                {/* <h3 className="text-lg font-medium">Proteins</h3> */}
                                {relatedProteins.length > 0 ? (
                                    <table className="min-w-full bg-white border border-gray-200">
                                        <thead className='bg-gray-200 z-10'>
                                            <tr className="bg-gray-100 text-left">
                                                <th className="py-2 px-4 border-b">ID</th>
                                                <th className="py-2 px-4 border-b">NAME</th>
                                                <th className="py-2 px-4 border-b">FULL NAME</th>
                                                <th className="py-2 px-4 border-b">SPECIES</th>
                                                <th className="py-2 px-4 border-b">PROTEIN EXPRESSED IN</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {relatedProteins.map((protein, index) => {
                                                const details = proteinDetails[protein.TAIL] || {};
                                                const showAll = showAllProteins[protein.TAIL];
                                                return (
                                                    <tr
                                                        key={index}
                                                        className="hover:bg-gray-50 cursor-pointer"

                                                    >
                                                        <td className="py-2 px-4 border-b"
                                                            onClick={() => setProteinIdPopup(protein.TAIL)}>
                                                            <span className="text-blue-600 underline">{protein.TAIL}</span>
                                                        </td>
                                                        <td className="py-2 px-4 border-b">{details.NAME || 'Loading...'}</td>
                                                        <td className="py-2 px-4 border-b">
                                                            {Array.isArray(details?.FULL_NAME)
                                                                ? details.FULL_NAME.join(", ")
                                                                : 'NA'}
                                                        </td>
                                                        <td className="py-2 px-4 border-b">{details.SPECIES || 'Loading...'}</td>
                                                        <td className="py-2 px-4 border-b">
                                                            {Array.isArray(details?.PROTEIN_EXPRESSED_IN) ? (
                                                                <>
                                                                    {details.PROTEIN_EXPRESSED_IN.slice(0, showAll ? undefined : 5).map((item, idx) =>
                                                                        item ? item.replace(/__/g, ', ').replace(/_/g, ' ') : 'NA'
                                                                    ).join(", ")}
                                                                    {details.PROTEIN_EXPRESSED_IN.length > 5 && (
                                                                        <button
                                                                            onClick={() => toggleShowAll(protein.TAIL)} // Pass the specific protein's TAIL
                                                                            className="px-2 text-blue-600 underline"
                                                                        >
                                                                            {showAll ? 'collapse' : 'Load More'} {/* Use state for the specific protein */}
                                                                        </button>
                                                                    )}
                                                                </>
                                                            ) : 'NA'}
                                                        </td>

                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                ) : (
                                    <div className="mt-4 px-4 text-gray-500">
                                        No related proteins available
                                    </div>
                                )}

                            </Tab.Panel>
                        )}

                    </Tab.Panels>
                </Tab.Group>
            </div>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <MuiAlert onClose={handleSnackbarClose} severity="info" sx={{ width: '100%' }}>
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
            {proteinIdPopup && (
                <ProteinDetailsPopup className="z-0" proteinId={proteinIdPopup} onClose={handleClosePopup} />
            )}
            {diseaseIdPopup && (
                <DiseaseDetailsPopup diseaseId={diseaseIdPopup} onClose={handleClosePopup} />
            )}

        </>
    )
}
export default DrugInterface;