import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AdmetPopup from './AdmetPopup';
import MoleculeEditorPopup from './MoleculeEditorPopup';
import OCL from 'openchemlib';
import DockingPopup from './DockingPopup';
import MergeTableView from './MergeTableView';
import db from '../../db'; // Dexie instance for IndexedDB
import axios from 'axios';
import { fetchParameters } from '../../redux/Actions/action';

const DrugProteinPair = ({ data, Query_ID }) => {
  const { drugDetails, proteinDetails } = data || {};
  const proteinID = proteinDetails?.PROTEIN;
  const drugID = drugDetails?.DRUG;

  const [tableData, setTableData] = useState({ repurposeableDrugs: [], KnownDrugs: [] });
  const [selectedAdmet, setSelectedAdmet] = useState(null);
  const [selectedDrugName, setSelectedDrugName] = useState('');
  const [editSmiles, setEditSmiles] = useState(null);
  const [dockSmiles, setDockSmiles] = useState(null);
  const [selectedPdbId, setSelectedPdbId] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  // Redux: parameters, conversation ID, user data
  const dispatch = useDispatch();
  const conv_ID = useSelector((state) => state.conv_ID);
  const UserData = useSelector((state) => state.UserDetails);
  const dockParameters = useSelector((state) => state.parameters.dock);

  // "predictInitialDrugs" parameters from Redux
  const initialDrugsParameters = useSelector((state) => state.parameters?.predictInitialDrugs) || {};
  const topK = initialDrugsParameters.topK ?? 100;
  const probThreshold = initialDrugsParameters.probThreshold ?? 0.6;

  // Local states for past-parameter dropdown
  const [pastParameters, setPastParameters] = useState([]);
  const [selectedParameter, setSelectedParameter] = useState(null); // from dropdown
  const [currentParameters, setCurrentParameters] = useState(null);

  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const interaction = "interaction"

  // -----------------------------
  // Fetch Redux parameters on mount
  // -----------------------------
  useEffect(() => {
    dispatch(fetchParameters());
  }, [dispatch]);

  // -----------------------------
  // Persist/Load selectedParameter to/from localStorage
  // -----------------------------
  // So it doesn't vanish when user leaves & returns to this page.
  useEffect(() => {
    if (!proteinID || !drugID) return;
    const storageKey = `dpPair_selectedParam_${proteinID}_${drugID}`;
    // Load from local storage on mount
    const storedParam = localStorage.getItem(storageKey);
    if (storedParam) {
      try {
        const parsed = JSON.parse(storedParam);
        setSelectedParameter(parsed); // e.g. { id, topK, probThreshold, name }
      } catch {
        // ignore any parsing error
      }
    }
  }, [proteinID, drugID]);

  useEffect(() => {
    if (!proteinID || !drugID) return;
    const storageKey = `dpPair_selectedParam_${proteinID}_${drugID}`;
    if (selectedParameter) {
      localStorage.setItem(storageKey, JSON.stringify(selectedParameter));
    } else {
      localStorage.removeItem(storageKey);
    }
  }, [selectedParameter, proteinID, drugID]);

  // -----------------------------
  // Construct cacheKey
  // -----------------------------
  // If user has selected an old parameter set from the dropdown, use that. Otherwise use Redux.
  const derivedTopK = selectedParameter?.topK ?? topK;
  const derivedProbT = selectedParameter?.probThreshold ?? probThreshold;

  const cacheKey = useMemo(() => {
    if (!proteinID || !drugID) return null;
    return `initial_${proteinID}_${drugID}_topK${derivedTopK}_probT${derivedProbT}`;
  }, [proteinID, drugID, derivedTopK, derivedProbT]);

  // -----------------------------
  // Extract param from a cacheKey
  // -----------------------------
  const extractParamFromKey = (key) => {
    // example format: "initial_<proteinID>_<drugID>_topK50_probT0.6"
    const regex = /^initial_\w+_\w+_topK(\d+)_probT([\d.]+)$/;
    const match = key.match(regex);
    if (!match) return null;
    return {
      topK: parseInt(match[1], 10),
      probThreshold: parseFloat(match[2]),
    };
  };

  // -----------------------------
  // Past Parameters (for the dropdown)
  // -----------------------------
  const fetchPastParameters = async () => {
    if (!proteinID || !drugID) return;
    try {
      const allEntries = await db.proteins.toArray();
      const prefix = `initial_${proteinID}_${drugID}_`;
      const matching = allEntries.filter((entry) => entry.id.startsWith(prefix));

      const parsed = matching
        .map((entry) => {
          const paramData = extractParamFromKey(entry.id);
          if (paramData) {
            return {
              id: entry.id,
              ...paramData,
              name: `TopK: ${paramData.topK}, ProbT: ${paramData.probThreshold}`,
            };
          }
          return null;
        })
        .filter(Boolean);
      setPastParameters(parsed);
    } catch (err) {
      console.error('Error fetching past parameters:', err);
    }
  };

  // -----------------------------
  // Main data fetch with caching
  // -----------------------------
  const fetchData = async (forceRefresh = false) => {
    if (!proteinID || !drugID) {
      setError('Invalid drug or protein data');
      return;
    }
    if (!cacheKey) {
      setError('No cacheKey available - missing protein or drug ID');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      // 1) Always fetch past parameters so the dropdown is up-to-date
      await fetchPastParameters();

      // 2) If we aren't forcing a refresh, see if we have a cached entry for the current (topK, probT)
      if (!forceRefresh) {
        const existing = await db.proteins.get(cacheKey);
        if (existing) {
          console.log('Using cached data for:', cacheKey);
          setTableData(existing.data);
          setCurrentParameters(extractParamFromKey(cacheKey));
          setLoading(false);
          return;
        }
      }

      // 3) No valid cache found OR user forced refresh => we fetch from server
      console.log('Fetching from server with params =>', {
        topK: derivedTopK,
        probThreshold: derivedProbT,
      });

      // Check if the relation is found for the given protein
      const relationsResp = await axios.get(`${BACKEND_URL}/relations/${proteinID}`);
      if (!relationsResp.data.found || !drugDetails?.C_SMILES) {
        setError(
          <span>
            Unable to predict interaction for target
            <span className="pl-2 text-[#494949]">{proteinID}</span>
            and drug
            <span className="pl-2 text-[#494949]">{drugID}</span>
          </span>
        );
        setLoading(false);
        return;
      }

      // Build payload for predict-initial-drugs
      const payload = {
        s_list: [drugDetails.C_SMILES],
        num_cores: 2,
        target: proteinID,
        top_k: derivedTopK,
        prob_threshold: derivedProbT,
      };

      const predictResponse = await fetch(`${BACKEND_URL}/auth/api/predict-initial-drugs`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });

      if (!predictResponse.ok) {
        const errorResponse = await predictResponse.json();
        throw new Error(errorResponse.error || `HTTP error! Status: ${predictResponse.status}`);
      }

      const { predTempResult, predAdmet_result } = await predictResponse.json();

      // Prepare table data
      const predictDrugsData = predTempResult.map((item) => ({
        DRUG__ID: item.DRUG__ID,
        C_SMILES: item.C_SMILES,
        PRED: item.PRED,
      }));

      const admetData = predictDrugsData.reduce((acc, drug, idx) => {
        acc[drug.C_SMILES] = predAdmet_result[idx] || {};
        return acc;
      }, {});

      const repurposeableDrugs = predictDrugsData.map((predictItem) => ({
        id: drugID || 'N/A',
        smiles: predictItem.C_SMILES,
        name: drugDetails?.NAME || 'N/A',
        cov: predictItem.PRED,
        dock: 'N/A',
        admet: admetData[predictItem.C_SMILES] || {},
        structure: 'View',
      }));

      // Fetch Known Drugs for this protein
      let KnownDrugs = [];
      try {
        const knownDrugsResp = await axios.get(`${BACKEND_URL}/relations/knownDrugs/${proteinID}`);
        const knownDrugsPayload = {
          s_list: knownDrugsResp.data.smilesList,
          num_cores: 2,
          target: proteinID,
          top_k: null,
          prob_threshold: 0,
        };
        const knownPredResp = await axios.post(
          `${BACKEND_URL}/auth/api/predict-initial-drugs`,
          knownDrugsPayload
        );
        const { predTempResult: knownDrugsData, predAdmet_result: knownAdmet } = knownPredResp.data;

        KnownDrugs = knownDrugsData.map((item, index) => {
          const relatedDrug =
            knownDrugsResp.data.relatedDrugs.find((drug) => drug.smile === item.C_SMILES) || {};
          return {
            id: relatedDrug.drugId || item.DRUG__ID,
            smiles: item.C_SMILES,
            truncatedSmiles:
              item.C_SMILES.length > 10 ? `${item.C_SMILES.substring(0, 10)}...` : item.C_SMILES,
            name: relatedDrug.name || 'Unknown',
            cov: item.PRED,
            dock: 'N/A',
            admet: knownAdmet[index] || {},
            structure: 'View',
          };
        });
      } catch (err) {
        console.error('Error fetching known drugs data:', err);
      }

      const finalData = { repurposeableDrugs, KnownDrugs };
      setTableData(finalData);
      setCurrentParameters(extractParamFromKey(cacheKey));

      // Store in IndexedDB
      await db.proteins.put({
        id: cacheKey,
        name: `Initial drug predictions: protein=${proteinID}, drug=${drugID}, topK=${derivedTopK}, probT=${derivedProbT}`,
        data: finalData,
        timestamp: Date.now(),
      });
      console.log('Cached data at key:', cacheKey);

      // Re-fetch past parameters to reflect newly added cache entry
      await fetchPastParameters();
    } catch (err) {
      console.error('Error fetching data or caching:', err);
      setError(`Error: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  // -----------------------------
  // useEffect => fetch data
  // -----------------------------
  useEffect(() => {
    // If we already have some tableData in state that matches the current param,
    // we might skip. But typically once user navigates away, state is lost.
    // So we call fetchData anyway.
    fetchData(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drugID, proteinID, derivedTopK, derivedProbT, selectedParameter]);

  // -----------------------------
  // Handle docking
  // -----------------------------
  const handleDockSmiles = async (smiles, pdbId) => {
    if (!tableData) return;

    // Mark those molecules as 'Docking'
    const markAsDocking = (arr) =>
      arr.map((item) => (smiles.includes(item.smiles) ? { ...item, dock: 'Docking' } : item));

    const updatedRepurposeable = markAsDocking(tableData.repurposeableDrugs);
    const updatedKnown = markAsDocking(tableData.KnownDrugs);
    const updatedTable = { repurposeableDrugs: updatedRepurposeable, KnownDrugs: updatedKnown };
    setTableData(updatedTable);

    const DockValues = {
      uniprot_acc: proteinID,
      pdb_id: pdbId,
      exhaustiveness: dockParameters?.exhaustiveness || 8,
      cpus: dockParameters?.cpus || 2,
      smiles_list: smiles,
    };

    const payload = {
      email: UserData.email,
      conversationID: conv_ID,
      queryID: Query_ID,
      DockValues,
    };

    try {
      const response = await fetch(`${BACKEND_URL}/auth/save-dock-result`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });
      const result = await response.json();
      if (!result?.results) {
        console.error('Docking returned no results or error:', result);
        throw new Error(result?.message || 'Docking error');
      }

      const dockingData = smiles.map((s) => {
        const filtered = result.results.filter((r) => r.smiles === s);
        return {
          smiles: s,
          dockData: filtered.map((res) => ({
            model: res.model,
            'binding affinity': res['binding affinity'],
            'rmsd l.b.': res['rmsd l.b.'],
            'rmsd u.b.': res['rmsd u.b.'],
          })),
        };
      });

      // Update table with "View" + dockResult
      const applyDockResults = (arr) =>
        arr.map((item) => {
          const match = dockingData.find((dd) => dd.smiles === item.smiles);
          if (match) {
            return { ...item, dock: 'View', dockResult: match.dockData };
          }
          return item;
        });

      const finalRepurposeable = applyDockResults(updatedRepurposeable);
      const finalKnown = applyDockResults(updatedKnown);
      const finalTable = { repurposeableDrugs: finalRepurposeable, KnownDrugs: finalKnown };

      setTableData(finalTable);

      // Update cache
      if (cacheKey) {
        await db.proteins.put({
          id: cacheKey,
          name: `Initial drug predictions: protein=${proteinID}, drug=${drugID}, topK=${derivedTopK}, probT=${derivedProbT}`,
          data: finalTable,
          timestamp: Date.now(),
        });
        console.log('Updated docking info cached at key:', cacheKey);
      }
    } catch (err) {
      console.error('Error during docking:', err);
    } finally {
      setDockSmiles(null);
    }
  };

  // -----------------------------
  // Handle Save Edited SMILES
  // -----------------------------
  const handleSaveSmiles = async (editedMoleculesArray = []) => {
    if (!Array.isArray(editedMoleculesArray) || editedMoleculesArray.length === 0) {
      setEditSmiles(null);
      return;
    }
    if (!tableData) return;

    // Update the table data for these molecules
    const updateEditedMolecules = (arr) =>
      arr.map((item) => {
        const match = editedMoleculesArray.find((m) => m.initialSmiles === item.smiles);
        if (match) {
          const molecule = OCL.Molecule.fromSmiles(match.currentSmiles);
          const svg = molecule.toSVG(300, 300);
          return {
            ...item,
            smiles: match.currentSmiles,
            truncatedSmiles:
              match.currentSmiles.length > 10
                ? `${match.currentSmiles.substring(0, 10)}...`
                : match.currentSmiles,
            editedMoleculeSvg: btoa(svg),
          };
        }
        return item;
      });

    const updatedRepurposeable = updateEditedMolecules(tableData.repurposeableDrugs);
    const updatedKnown = updateEditedMolecules(tableData.KnownDrugs);
    const newTableData = { repurposeableDrugs: updatedRepurposeable, KnownDrugs: updatedKnown };

    setTableData(newTableData);

    // Prepare the payload
    const moleculesPayload = editedMoleculesArray.map(({ initialSmiles, currentSmiles }) => {
      // find the original item by initialSmiles
      const combinedArr = [...tableData.repurposeableDrugs, ...tableData.KnownDrugs];
      const original = combinedArr.find((x) => x.smiles === initialSmiles);
      return {
        sourceMoleculeID: original?.id || '',
        sourceMoleculeSMILES: initialSmiles,
        editedMoleculeSMILES: currentSmiles,
      };
    });

    try {
      const payload = {
        email: UserData.email,
        conversationID: conv_ID,
        queryName: `Interaction between ${drugID} and ${proteinID}`,
        queryID: Query_ID,
        proteinID: proteinID,
        molecules: moleculesPayload,
      };

      const resp = await fetch(`${BACKEND_URL}/auth/save-edited-molecule`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });
      const result = await resp.json();
      if (!result.status) {
        console.error('Error saving molecules:', result.message);
      }

      // Update cache with the edited molecules
      if (cacheKey) {
        await db.proteins.put({
          id: cacheKey,
          name: `Initial drug predictions: protein=${proteinID}, drug=${drugID}, topK=${derivedTopK}, probT=${derivedProbT}`,
          data: newTableData,
          timestamp: Date.now(),
        });
        console.log('Cache updated with edited molecules');
      }
    } catch (error) {
      console.error('Error saving edited molecules:', error);
    }

    setEditSmiles(null);
  };

  // -----------------------------
  // Render
  // -----------------------------
  return (
    <div className="p-4">
      {error ? (
        <div className="text-xl font-bold mb-4 text-gray-400">{error}</div>
      ) : (
        <>
          <h2 className="text-xl font-bold mb-4 text-[#494949]">
            Predicted interaction between {drugID} and {proteinID}
          </h2>

          {/* Past Parameter Dropdown */}
          {pastParameters.length > 0 && (
            <div className="mb-4">
              <label
                htmlFor="past-parameters"
                className="block text-sm font-medium text-gray-700"
              >
                Load Previous Parameters:
              </label>
              <select
                id="past-parameters"
                name="past-parameters"
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300
                           focus:outline-none focus:ring-indigo-500
                           focus:border-indigo-500 sm:text-sm rounded-md"
                value={selectedParameter ? selectedParameter.id : ''}
                onChange={(e) => {
                  const chosen = pastParameters.find((p) => p.id === e.target.value);
                  if (chosen) setSelectedParameter(chosen);
                  else setSelectedParameter(null);
                }}
              >
                <option value="">-- Use Current Settings --</option>
                {pastParameters.map((param) => (
                  <option key={param.id} value={param.id}>
                    {param.name}
                  </option>
                ))}
              </select>
            </div>
          )}

          {/* Force Refresh Button */}
          {/* {pastParameters.length > 0 && (
            <div className="mb-4">
              <button
                className="text-blue-500 underline cursor-pointer"
                onClick={() => fetchData(true)}
                disabled={loading}
              >
                {loading ? 'Refreshing...' : 'Refresh with New Settings'}
              </button>
            </div>
          )} */}

          {/* Loading / Table */}
          {loading ? (
            <div className="text-xl font-bold mb-4 text-gray-300">Loading...</div>
          ) : (
            <>
              {tableData.repurposeableDrugs.length > 0 || tableData.KnownDrugs.length > 0 ? (
                <MergeTableView
                  data={tableData}
                  selectable={true}
                  onAdmetClick={(admet, drugName) => {
                    setSelectedAdmet(admet);
                    setSelectedDrugName(drugName);
                  }}
                  onEditSmiles={(smiles) => setEditSmiles(smiles)}
                  onDockSmiles={(smiles) => setDockSmiles(smiles)}
                  selectedPdbId={selectedPdbId}
                  isRowClickable={true}
                  name={interaction}
                />
              ) : (
                <div className="text-xl font-bold mb-4 text-gray-300">
                  No data available
                </div>
              )}
            </>
          )}
        </>
      )}

      {/* Popups */}
      {editSmiles && (
        <MoleculeEditorPopup
          smilesList={Array.isArray(editSmiles) ? editSmiles : [editSmiles]}
          onSaveAll={handleSaveSmiles}
          onClose={() => setEditSmiles(null)}
        />
      )}
      {dockSmiles && (
        <DockingPopup
          proteinID={proteinID}
          smilesList={Array.isArray(dockSmiles) ? dockSmiles : [dockSmiles]}
          onDock={(s, pdbID) => {
            setSelectedPdbId(pdbID);
            handleDockSmiles(s, pdbID);
          }}
          onClose={() => setDockSmiles(null)}
        />
      )}
      {selectedAdmet && (
        <AdmetPopup
          admet={selectedAdmet}
          drugName={selectedDrugName}
          onClose={() => setSelectedAdmet(null)}
        />
      )}
    </div>
  );
};

export default DrugProteinPair;