import { useState, useEffect } from 'react';
import axios from 'axios';
import { Tooltip } from '@mui/material';
import { InformationCircleIcon } from '@heroicons/react/24/outline';

const Settings = () => {
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
    const [topKRepurpose, setTopKRepurpose] = useState(100);
    const [probThresholdRepurpose, setProbThresholdRepurpose] = useState(0.6);
    const [topKInitialDrugs, setTopKInitialDrugs] = useState(100);
    const [probThresholdInitialDrugs, setProbThresholdInitialDrugs] = useState(0.6);
    const [exhaustiveness, setExhaustiveness] = useState(8);
    const [cpus, setCpus] = useState(2);
    const [iterations, setIterations] = useState(1);
    const [topK, setTopK] = useState(100);
    const [seedProbThreshold, setSeedProbThreshold] = useState(0.8);
    const [iterProbThreshold, setIterProbThreshold] = useState(0.8);
    const [simSearchThreshold, setSimSearchThreshold] = useState(0.8);
    const [simSearchWorkers, setSimSearchWorkers] = useState(1);

    useEffect(() => {
        // Fetch current parameters from backend
        axios.get(`${BACKEND_URL}/auth/get-parameters`, { withCredentials: true })
            .then(response => {
                if (response.data.status) {
                    const parameters = response.data.parameters;
    
                    // Update the state with the parameters
                    if (parameters.predictRepurpose) {
                        setTopKRepurpose(parameters.predictRepurpose.topK);
                        setProbThresholdRepurpose(parameters.predictRepurpose.probThreshold);
                    }
                    if (parameters.predictInitialDrugs) {
                        setTopKInitialDrugs(parameters.predictInitialDrugs.topK);
                        setProbThresholdInitialDrugs(parameters.predictInitialDrugs.probThreshold);
                    }
                    if (parameters.dock) {
                        setExhaustiveness(parameters.dock.exhaustiveness);
                        setCpus(parameters.dock.cpus);
                    }
                    if (parameters.predictNovelDrugs) {
                        setIterations(parameters.predictNovelDrugs.iterations);
                        setTopK(parameters.predictNovelDrugs.topK);
                        setSeedProbThreshold(parameters.predictNovelDrugs.seedProbThreshold);
                        setIterProbThreshold(parameters.predictNovelDrugs.iterProbThreshold);
                        setSimSearchThreshold(parameters.predictNovelDrugs.simSearchThreshold);
                        setSimSearchWorkers(parameters.predictNovelDrugs.simSearchWorkers);
                    }
                } else {
                    console.error('Error fetching parameters:', response.data.message);
                }
            })
            .catch(error => {
                console.error('Error fetching parameters:', error);
            });
    }, []);    

    const handlePredictRepurposeChange = () => {
        const parameters = {
            predictRepurpose: {
                topK: Number(topKRepurpose),
                probThreshold: Number(probThresholdRepurpose),
            },
        };
    
        axios.post(`${BACKEND_URL}/auth/update-parameters`, { parameters }, { withCredentials: true })
            .then(response => {
                if (response.data.status) {
                    console.log('parameters updated successfully:', response.data.parameters);
                } else {
                    console.error('Error updating parameters:', response.data.message);
                }
            })
            .catch(error => {
                console.error('Error updating parameters:', error);
            });
    };    

    const handlePredictInitialDrugsChange = () => {
        const parameters = {
            predictInitialDrugs: {
                topK: Number(topKInitialDrugs),
                probThreshold: Number(probThresholdInitialDrugs),
            },
        };
    
        axios.post(`${BACKEND_URL}/auth/update-parameters`, { parameters }, { withCredentials: true })
            .then(response => {
                if (response.data.status) {
                    console.log('parameters updated successfully:', response.data.parameters);
                } else {
                    console.error('Error updating parameters:', response.data.message);
                }
            })
            .catch(error => {
                console.error('Error updating parameters:', error);
            });
    };    

    const handleDockChange = () => {
        const parameters = {
            dock: {
                exhaustiveness: Number(exhaustiveness),
                cpus: Number(cpus),
            },
        };
    
        axios.post(`${BACKEND_URL}/auth/update-parameters`, { parameters }, { withCredentials: true })
            .then(response => {
                if (response.data.status) {
                    console.log('parameters updated successfully:', response.data.parameters);
                } else {
                    console.error('Error updating parameters:', response.data.message);
                }
            })
            .catch(error => {
                console.error('Error updating parameters:', error);
            });
    };    

    const handleNovelDrugsChange = () => {
        const parameters = {
            predictNovelDrugs: {
                iterations: Number(iterations),
                topK: Number(topK),
                seedProbThreshold: Number(seedProbThreshold),
                iterProbThreshold: Number(iterProbThreshold),
                simSearchThreshold: Number(simSearchThreshold),
                simSearchWorkers: Number(simSearchWorkers),
            },
        };
    
        axios.post(`${BACKEND_URL}/auth/update-parameters`, { parameters }, { withCredentials: true })
            .then(response => {
                if (response.data.status) {
                    console.log('parameters updated successfully:', response.data.parameters);
                } else {
                    console.error('Error updating parameters:', response.data.message);
                }
            })
            .catch(error => {
                console.error('Error updating parameters:', error);
            });
    };    

    return (
        <div className="p-4 space-y-4">
            <div>
                <h3 className="text-lg font-semibold mb-2">Parameters</h3>

                {/* Predict Repurpose Panel */}
                <div className="border rounded-lg shadow-lg px-4 my-4">
                    <div className="my-4">
                        <h3 className="text-lg font-semibold mb-2">Repurposing parameters</h3>
                        <div className="mb-4">
                            <label className="flex font-medium mb-1">Top K
                                <Tooltip title={`No. of top predicted drugs to display`} placement="top">
                                    <InformationCircleIcon className="ml-1 h-4 w-4 text-gray-400 cursor-pointer uniform-icon" />
                                </Tooltip>
                            </label>
                            <input
                                type="number"
                                className="border p-2 mt-2 rounded-lg w-full"
                                placeholder="Enter custom value"
                                value={topKRepurpose}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const numericValue = Number(value);
                                    if (value === '' || (Number.isInteger(numericValue) && numericValue >= 0 && numericValue <= 100)) {
                                        setTopKRepurpose(value);
                                    }
                                }}
                                step="1"
                                min="0"
                                max="100"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="flex font-medium mb-1">Cut-off Score
                                <Tooltip title={`score cutoff  (pick predicted candidates above a certain score) `} placement="top">
                                    {/* pick predicted candidates above a certain score */}
                                    <InformationCircleIcon className="ml-1 h-4 w-4 text-gray-400 cursor-pointer uniform-icon" />
                                </Tooltip>
                            </label>
                            <input
                                type="number"
                                className="border p-2 mt-2 rounded-lg w-full"
                                placeholder="Enter custom value"
                                value={probThresholdRepurpose}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const numericValue = parseFloat(value);
                                    if (value === '' || (numericValue >= 0 && numericValue <= 1)) {
                                        setProbThresholdRepurpose(value);
                                    }
                                }}
                                step="0.01"
                                min="0"
                                max="1"
                            />
                        </div>
                        <button
                            className="px-3 py-1 bg-[#EEEFF2] rounded flex items-center hover:bg-[#DDE0E5] transition duration-200"
                            onClick={handlePredictRepurposeChange}>
                            <span className="text-gray-800 font-medium">Save</span>
                        </button>
                    </div>
                </div>

                {/* Predict Initial Drugs Panel */}
                <div className="border rounded-lg shadow-lg px-4 my-4">
                    <div className="my-4">
                        <h3 className="text-lg font-semibold mb-2">Drug scoring parameters</h3>
                        <div className="mb-4">
                            <label className="flex font-medium mb-1">Top K
                                <Tooltip title={`No. of top predicted drugs to display`} placement="top">
                                    <InformationCircleIcon className="ml-1 h-4 w-4 text-gray-400 cursor-pointer uniform-icon" />
                                </Tooltip>
                            </label>
                            <input
                                type="number"
                                className="border p-2 mt-2 rounded-lg w-full"
                                placeholder="Enter custom value"
                                value={topKInitialDrugs === null ? '' : topKInitialDrugs}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const numericValue = Number(value);

                                    // Allow empty input or valid integer values within the range
                                    if (value === '' || (Number.isInteger(numericValue) && numericValue >= 1 && numericValue <= 100)) {
                                        setTopKInitialDrugs(value === '' ? null : numericValue);
                                    }
                                }}
                                step="1"
                                min="1"
                                max="100"
                            />

                        </div>
                        <div className="mb-4">
                            <label className="flex font-medium mb-1">Cut-off Score
                                <Tooltip title={`score cutoff (pick predicted candidates above a certain score)`} placement="top">
                                    <InformationCircleIcon className="ml-1 h-4 w-4 text-gray-400 cursor-pointer uniform-icon" />
                                </Tooltip>
                            </label>
                            <input
                                type="number"
                                className="border p-2 mt-2 rounded-lg w-full"
                                placeholder="Enter custom value"
                                value={probThresholdInitialDrugs}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const numericValue = parseFloat(value);
                                    if (value === '' || (numericValue >= 0 && numericValue <= 1)) {
                                        setProbThresholdInitialDrugs(value);
                                    }
                                }}
                                step="0.01"
                                min="0"
                                max="1"
                            />
                        </div>
                        <button
                            className="px-3 py-1 bg-[#EEEFF2] rounded flex items-center hover:bg-[#DDE0E5] transition duration-200"
                            onClick={handlePredictInitialDrugsChange}>
                            <span className="text-gray-800 font-medium">Save</span>
                        </button>
                    </div>
                </div>

                {/* Dock Panel */}
                <div className="border rounded-lg shadow-lg px-4 my-4">
                    <div className="my-4">
                        <h3 className="text-lg font-semibold mb-2">Dock</h3>
                        <div className="mb-4">
                            <label className="flex font-medium mb-1">Exhaustiveness
                                <Tooltip title={`More exhaustiveness indicates a more comprehensive docking`} placement="top">
                                    <InformationCircleIcon className="ml-1 h-4 w-4 text-gray-400 cursor-pointer uniform-icon" />
                                </Tooltip>
                            </label>
                            <select
                                className="border p-2 rounded-lg w-full"
                                value={exhaustiveness}
                                onChange={(e) => setExhaustiveness(e.target.value)}
                            >
                                <option value={8}>8</option>
                                <option value={16}>16</option>
                                <option value={32}>32</option>
                            </select>
                        </div>
                        <div className="mb-4">
                            <label className="flex font-medium mb-1">CPUs
                                <Tooltip title={`No. of parallel computations(More is faster)`} placement="top">
                                    <InformationCircleIcon className="ml-1 h-4 w-4 text-gray-400 cursor-pointer uniform-icon" />
                                </Tooltip>
                            </label>
                            <select
                                className="border p-2 rounded-lg w-full"
                                value={cpus}
                                onChange={(e) => setCpus(e.target.value)}
                            >
                                <option value={2}>2</option>
                                <option value={3}>3</option>
                                <option value={4}>4</option>
                                <option value={5}>5</option>
                                <option value={6}>6</option>
                                <option value={7}>7</option>
                                <option value={8}>8</option>
                            </select>
                        </div>
                        <button
                            className="px-3 py-1 bg-[#EEEFF2] rounded flex items-center hover:bg-[#DDE0E5] transition duration-200"
                            onClick={handleDockChange}>
                            <span className="text-gray-800 font-medium">Save</span>
                        </button>
                    </div>
                </div>

                {/* Novel Drugs */}
                {/* <div className="border rounded-lg shadow-lg px-4 my-4">
                    <div className="my-4">
                        <h3 className="text-lg font-semibold mb-4">NovelDrugs</h3>
                        <div className="mb-4">
                            <label className="flex font-medium mb-1">
                                Number of iterations
                                <Tooltip title="Number of iterations" placement="top">
                                    <InformationCircleIcon className="ml-1 h-4 w-4 text-gray-400 cursor-pointer" />
                                </Tooltip>
                            </label>
                            <input
                                type="number"
                                step={1}
                                className="border p-2 rounded-lg w-full"
                                value={iterations}
                                min={1}
                                max={3}
                                onChange={(e) => setIterations(parseInt(e.target.value))}
                            />
                        </div>

                        <div className="mb-4">
                            <label className="flex font-medium mb-1">
                                Top K
                                <Tooltip title="Number of top results to retrieve" placement="top">
                                    <InformationCircleIcon className="ml-1 h-4 w-4 text-gray-400 cursor-pointer" />
                                </Tooltip>
                            </label>
                            <input
                                type="number"
                                className="border p-2 rounded-lg w-full"
                                value={topK}
                                min={1}
                                max={500}
                                onChange={(e) => setTopK(parseInt(e.target.value))}
                            />
                        </div>

                        <div className="mb-4">
                            <label className="flex font-medium mb-1">
                                Seed Probability Threshold
                                <Tooltip title="Minimum allowed value is 0.6" placement="top">
                                    <InformationCircleIcon className="ml-1 h-4 w-4 text-gray-400 cursor-pointer" />
                                </Tooltip>
                            </label>
                            <input
                                type="number"
                                step="0.1"
                                className="border p-2 rounded-lg w-full"
                                value={seedProbThreshold}
                                min={0.6}
                                onChange={(e) => setSeedProbThreshold(parseFloat(e.target.value))}
                            />
                        </div>

                        <div className="mb-4">
                            <label className="flex font-medium mb-1">
                                Iteration Probability Threshold
                                <Tooltip title="Minimum allowed value is 0.6" placement="top">
                                    <InformationCircleIcon className="ml-1 h-4 w-4 text-gray-400 cursor-pointer" />
                                </Tooltip>
                            </label>
                            <input
                                type="number"
                                step="0.1"
                                className="border p-2 rounded-lg w-full"
                                value={iterProbThreshold}
                                min={0.6}
                                max={1}
                                onChange={(e) => setIterProbThreshold(parseFloat(e.target.value))}
                            />
                        </div>

                        <div className="mb-4">
                            <label className="flex font-medium mb-1">
                                Similarity Search Threshold
                                <Tooltip title="Value should be between 0.7 and 1" placement="top">
                                    <InformationCircleIcon className="ml-1 h-4 w-4 text-gray-400 cursor-pointer" />
                                </Tooltip>
                            </label>
                            <input
                                type="number"
                                step="0.1"
                                className="border p-2 rounded-lg w-full"
                                value={simSearchThreshold}
                                min={0.7}
                                max={1}
                                onChange={(e) => setSimSearchThreshold(parseFloat(e.target.value))}
                            />
                        </div>

                        <div className="mb-4">
                            <label className="flex font-medium mb-1">
                                Similarity Search Workers
                                <Tooltip title="Number of parallel workers (max 5)" placement="top">
                                    <InformationCircleIcon className="ml-1 h-4 w-4 text-gray-400 cursor-pointer" />
                                </Tooltip>
                            </label>
                            <select
                                className="border p-2 rounded-lg w-full"
                                value={simSearchWorkers}
                                onChange={(e) => setSimSearchWorkers(parseInt(e.target.value))}
                            >
                                {[1, 2, 3, 4, 5].map((num) => (
                                    <option key={num} value={num}>
                                        {num}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <button
                            className="px-3 py-1 bg-[#EEEFF2] rounded flex items-center hover:bg-[#DDE0E5] transition duration-200"
                            onClick={handleNovelDrugsChange}
                        >
                            <span className="text-gray-800 font-medium">Save</span>
                        </button>
                    </div>

                </div> */}
            </div>
        </div>
    );
};

export default Settings;