import React, { useRef, useState } from 'react';
import { FiChevronLeft, FiChevronRight, FiClipboard } from 'react-icons/fi';
import Tooltip from '@mui/material/Tooltip';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import ReactPaginate from 'react-paginate';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import DrugDetailsPopup from './DrugDetailsPopup';
import ExcelJS from 'exceljs';
import { AiOutlineDownload } from 'react-icons/ai';
import DockDataPopup from './DockDataPopup';
import admetMetadata from './admet_categories.json';
import { useSelector } from 'react-redux';

const itemsPerPage = 5;

const MergeTableView = ({ name,isRowClickable, data, onAdmetClick, onEditSmiles, onDockSmiles, selectedPdbId, argument }) => {

    let heading;

    switch (name) {
      case 'novel':
        heading = 'Novel Drugs';
        break;
      case 'Repurpose':
        heading = 'Repurposable Drugs';
        break;
      case 'interaction':
        heading = 'Interaction between given drug and target';
        break;
      default:
        heading = 'Unknown Category';
    }

    const columns = [
        'ID',
        'Truncated SMILES',
        'Name',
        'COV',
        'Dock',
        'Admet Plot',
        'Molecule Structure',
        'Edited Structure',
        'Select',
    ];

    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [selectedItems, setSelectedItems] = useState([]);
    const [open, setOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [drugIdPopup, setDrugIdPopup] = useState(null);
    const scrollRef = useRef(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [currentDockData, setCurrentDockData] = useState([]);
    const [currentDocksmile, setCurrentDockSmile] = useState([]);

    const repurposeParameters = useSelector((state) => state.parameters.predictRepurpose);
    const topk = repurposeParameters.topK || '100';
    const probThreshold = repurposeParameters.probThreshold || '0.6';
    // console.log(repurposeParameters)

    // Combine `repurposeableDrugs` and `KnownDrugs` into a single array for processing
    const combinedData = [...(data.repurposeableDrugs || []), ...(data.KnownDrugs || [])];

    const handleCellClick = (row) => {
        if (row.dock === 'View') {
            setCurrentDockData(row.dockResult);
            setCurrentDockSmile(row.smiles)
            // console.log(row.dockResult)
            // console.log(row.smiles)
            setIsPopupOpen(true);
        }
    };

    const closePopup = () => {
        setIsPopupOpen(false);
        // setCurrentDockData([]);
    };

    // const handleHorizontalScroll = (event) => {
    //     if (scrollRef.current) {
    //         // scrollRef.current.scrollLeft += event.deltaY; // Adjust scrollLeft by the vertical scroll delta
    //         scrollRef.current.scrollTop += event.deltaY;

    //     }
    // };

    const handleSnackbarClose = () => {
        setOpen(false);
    };

    const handleCheckboxChange = (id) => {
        setSelectedItems((prevSelectedItems) =>
            prevSelectedItems.includes(id)
                ? prevSelectedItems.filter((item) => item !== id)
                : [...prevSelectedItems, id]
        );
    };
    const handleDockButtonClick = () => {
        if (selectedItems.length === 0) {
            setSnackbarMessage('Please select at least one item to Dock');
            setOpen(true);
        } else {
            // Combine repurposeableDrugs and KnownDrugs into a single array for processing
            const combinedData = [...data.repurposeableDrugs, ...data.KnownDrugs];
            const smilesList = selectedItems.map(id => {
                const item = combinedData.find(item => item.id === id);
                if (item) {
                    console.log('Selected Smiles:', item.smiles); // Debugging line
                    return item.smiles;
                } else {
                    console.error('Item not found for ID:', id);
                    return null;
                }
            }).filter((smiles) => smiles !== null); // Filter out null values
            console.log('SMILES List to Dock :', smilesList);
            onDockSmiles(smilesList)
        }
    }

    const handleEditButtonClick = () => {
        if (selectedItems.length === 0) {
            setSnackbarMessage('Please select at least one item to edit.');
            setOpen(true);
        } else {
            // Combine both arrays into a single array for searching
            const combinedData = [...data.repurposeableDrugs, ...data.KnownDrugs];

            if (selectedItems.length === 1) {
                const item = combinedData.find((item) => item.id === selectedItems[0]);
                if (item) {
                    onEditSmiles([item.smiles]);
                } else {
                    console.error('Item not found for single edit:', selectedItems[0]);
                }
            } else {
                const smilesList = selectedItems.map((id) => {
                    const item = combinedData.find((item) => item.id === id);
                    if (item) {
                        return item.smiles;
                    } else {
                        console.error('Item not found for batch edit:', id);
                        return null;
                    }
                }).filter((smiles) => smiles !== null); // Filter out any null values
                onEditSmiles(smilesList);
            }
        }
    };


    // const handleCopyToClipboard = (smiles) => {
    //   navigator.clipboard.writeText(smiles).then(() => {
    //     setSnackbarMessage('SMILES copied to clipboard!');
    //     setOpen(true);
    //   }).catch((err) => {
    //     console.error('Failed to copy: ', err);
    //     setSnackbarMessage('Failed to copy SMILES to clipboard.');
    //     setOpen(true);
    //   });
    // };

    const handleCopyToClipboard = (smiles) => {
        // console.log(smiles);
        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(smiles)
                .then(() => {
                    setSnackbarMessage('SMILE copied to clipboard!');
                    setOpen(true);
                })
                .catch((err) => {
                    // console.error('Failed to copy: ', err);
                    setSnackbarMessage('Failed to copy SMILES to clipboard.');
                    setOpen(true);
                });
        } else {
            const textarea = document.createElement('textarea');
            textarea.value = smiles;
            document.body.appendChild(textarea);
            textarea.select();
            try {
                document.execCommand('copy');
                setSnackbarMessage('SMILES copied to clipboard!');
                // console.log('Fallback: Text copied!');
            } catch (err) {
                // console.error('Fallback copy failed: ', err);
                setSnackbarMessage('Failed to copy SMILES to clipboard.');
            }
            document.body.removeChild(textarea);
            setOpen(true);
        }
    };
    // console.log(data)
    // Check if the name property exists in any data row
    // const hasNameProperty = combinedData.some((item) => item.hasOwnProperty('name'));


    // console.log(displayData)
    const repurposeableDrugs = data.repurposeableDrugs || [];
    const knownDrugs = data.KnownDrugs || [];

    const hasNameProperty = [...repurposeableDrugs].some((item) => item.hasOwnProperty('name'));
    const hasNamePropertyInKnown = [...knownDrugs].some((item) => item.hasOwnProperty('name'));
    const filteredData = repurposeableDrugs.filter((item) =>
        Object.values(item).some((value) =>
            value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    const pageCount = Math.ceil(filteredData.length / itemsPerPage);
    const displayData = filteredData.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

    const filteredRepurposeableDrugs = repurposeableDrugs.filter((item) =>
        Object.values(item).some((value) =>
            value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    const filteredKnownDrugs = knownDrugs.filter((item) =>
        Object.values(item).some((value) =>
            value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );
    const hasEditedMoleculeSvg = displayData.some(row => row.editedMoleculeSvg);
    const dynamicColumns = columns.filter(column =>
        (column !== 'Name' || hasNameProperty || hasNamePropertyInKnown) &&
        (column !== 'Edited Structure' || hasEditedMoleculeSvg)
    );

    const dynamicColumns2 = columns.filter(column =>
        (column !== 'Name' || hasNameProperty ) &&
        (column !== 'Edited Structure' || hasEditedMoleculeSvg)
    );

    const handlePageClick = ({ selected }) => {
        setCurrentPage(selected);
    };

    const handleClosePopup = () => {
        setDrugIdPopup(null);
    };

    // // Function to convert SVG to PNG
    // const convertSvgToPng = (svgBase64) => {
    //     return new Promise((resolve, reject) => {
    //         const svgData = 'data:image/svg+xml;base64,' + svgBase64;
    //         const img = new Image();
    //         img.crossOrigin = 'anonymous'; // This is important for CORS issues
    //         img.onload = function () {
    //             const canvas = document.createElement('canvas');
    //             canvas.width = img.naturalWidth;
    //             canvas.height = img.naturalHeight;
    //             const ctx = canvas.getContext('2d');
    //             ctx.drawImage(img, 0, 0);
    //             const pngDataUrl = canvas.toDataURL('image/png');
    //             const pngBase64 = pngDataUrl.replace(/^data:image\/png;base64,/, '');
    //             resolve(pngBase64);
    //         };
    //         img.onerror = function (e) {
    //             reject(e);
    //         };
    //         img.src = svgData;
    //     });
    // };

    // Function to handle download
    const handleDownload = async () => {
        const workbook = new ExcelJS.Workbook();

        // First worksheet: Table Data
        const worksheet = workbook.addWorksheet('Table Data');

        // Define columns, excluding 'Select' and image columns
        const excelColumns = [
            { header: 'ID', key: 'ID', width: 10 },
            { header: 'Truncated SMILES', key: 'Truncated_SMILES', width: 30 },
            { header: 'Name', key: 'Name', width: 20 },
            { header: 'COV', key: 'COV', width: 10 },
            { header: 'Dock', key: 'Dock', width: 10 },
        ];
        worksheet.columns = excelColumns;

        // Add header styles
        worksheet.getRow(1).eachCell((cell) => {
            cell.font = { bold: true };
            cell.alignment = { vertical: 'middle', horizontal: 'center' };
        });

        // Populate the worksheet
        filteredData.forEach((row) => {
            worksheet.addRow({
                ID: row.id,
                Truncated_SMILES: row.smiles,
                Name: row.name,
                COV: row.cov,
                Dock: row.dock,
            });
        });

        // Create the ADMET Data worksheet
        const admetWorksheet = workbook.addWorksheet('ADMET Data');

        admetWorksheet.columns = [
            { header: 'ID', key: 'ID', width: 10 },
            { header: 'Truncated SMILES', key: 'Truncated_SMILES', width: 30 },
            { header: 'Name', key: 'Name', width: 20 },
            { header: 'Category', key: 'Category', width: 20 },
            { header: 'Property Name', key: 'Property_Name', width: 30 },
            { header: 'Prediction', key: 'Prediction', width: 20 },
            { header: 'Drug Bank Percentile', key: 'Drug_Bank_Percentile', width: 20 },
            { header: 'Units', key: 'Units', width: 15 },
            { header: 'Accuracy', key: 'Accuracy', width: 15 },
        ];

        // Add header styles
        admetWorksheet.getRow(1).eachCell((cell) => {
            cell.font = { bold: true };
            cell.alignment = { vertical: 'middle', horizontal: 'center' };
        });

        // Populate the ADMET Data worksheet
        filteredData.forEach((row) => {
            const id = row.id;
            const truncatedSmiles = row.smiles;
            const name = row.name;
            const admetData = row.admet || {};

            admetMetadata.forEach((property) => {
                const propertyId = property.id;
                const category = property.category;
                const propertyName = property.name || propertyId;
                const units = property.units || 'N/A';
                const accuracy = property.AUROC ?? property['R^2'];
                const accuracyValue = accuracy === null || isNaN(accuracy) ? 'N/A' : accuracy;

                if (admetData.hasOwnProperty(propertyId)) {
                    const prediction = admetData[propertyId];
                    const percentileKey = `${propertyId}_drugbank_approved_percentile`;
                    const drugBankPercentile = admetData.hasOwnProperty(percentileKey)
                        ? admetData[percentileKey]
                        : 'N/A';

                    admetWorksheet.addRow({
                        ID: id,
                        Truncated_SMILES: truncatedSmiles,
                        Name: name,
                        Category: category,
                        Property_Name: propertyName,
                        Prediction: prediction,
                        Drug_Bank_Percentile: drugBankPercentile,
                        Units: units,
                        Accuracy: accuracyValue,
                    });
                }
            });
        });

        // Check if any docking data is available
        const hasDockingData = filteredData.some(
            (row) => row.dock === 'View' && row.dockResult && row.dockResult.length > 0
        );

        if (hasDockingData) {
            // Create the Docking Data worksheet
            const dockingWorksheet = workbook.addWorksheet('Docking Data');

            // Define columns
            dockingWorksheet.columns = [
                { header: 'ID', key: 'ID', width: 10 },
                { header: 'SMILES', key: 'SMILES', width: 30 },
                { header: 'Name', key: 'Name', width: 20 },
                { header: 'PDB ID', key: 'PDB_ID', width: 10 },
                { header: 'Model', key: 'Model', width: 10 },
                { header: 'Binding Affinity (kcal/mol)', key: 'Binding_Affinity', width: 25 },
                { header: 'RMSD Lower Bound', key: 'RMSD_LB', width: 20 },
                { header: 'RMSD Upper Bound', key: 'RMSD_UB', width: 20 },
            ];

            // Add header styles
            dockingWorksheet.getRow(1).eachCell((cell) => {
                cell.font = { bold: true };
                cell.alignment = { vertical: 'middle', horizontal: 'center' };
            });

            // Populate the Docking Data worksheet
            filteredData.forEach((row) => {
                if (row.dock === 'View' && row.dockResult && row.dockResult.length > 0) {
                    const id = row.id;
                    const smiles = row.smiles;
                    const name = row.name;
                    const pdbId = selectedPdbId || 'N/A';

                    row.dockResult.forEach((dock) => {
                        dockingWorksheet.addRow({
                            ID: id,
                            SMILES: smiles,
                            Name: name,
                            PDB_ID: pdbId,
                            Model: dock.model,
                            Binding_Affinity: dock['binding affinity'],
                            RMSD_LB: dock['rmsd l.b.'],
                            RMSD_UB: dock['rmsd u.b.'],
                        });
                    });
                }
            });
        }

        // Generate buffer and trigger download
        workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], { type: 'application/octet-stream' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'table_data.xlsx';
            link.click();
        });
    };

    return (

        <div >

            <div className="flex justify-end items-center">
                {/* {filteredData.length > 1 && ( */}
                <input
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="border p-1.5 mr-3 rounded-md"
                />
                {/* )} */}
                <button
                    onClick={handleDockButtonClick}
                    className="px-3 py-1 ml-3 bg-[#EEEFF2] rounded flex items-center hover:bg-[#DDE0E5] transition duration-200"
                    title='Dock Top Results'
                >
                    Dock
                </button>
                <button
                    onClick={handleEditButtonClick}
                    className="px-3 py-1 ml-3 bg-[#EEEFF2] rounded flex items-center hover:bg-[#DDE0E5] transition duration-200"
                    title={selectedItems.length <= 1 ? 'Edit selected item' : 'Batch edit selected items'}
                >
                    {selectedItems.length <= 1 ? 'Edit' : 'Batch Edit'}
                </button>
                <button
                    className="px-3 py-1 ml-3 bg-[#EEEFF2] rounded flex items-center hover:bg-[#DDE0E5] transition duration-200"
                    onClick={handleDownload}
                >
                    <AiOutlineDownload className="w-5 h-5 text-gray-700" title='download' />
                </button>
            </div>

            {filteredKnownDrugs.length === 0 ?
                (
                    <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-2 mt-4 rounded-md">
                        <p className="text-l">No Known drugs available for this target</p>
                    </div>) :

                (
                    <>
                        <h2 className="text-xl font-bold mb-2 text-[#494949]">Known drugs</h2>
                        <div className={`overflow-x-auto w-full custom-scrollbar overflow-y-auto ${displayData.length === 0 ? 'max-h-[500px]' : 'max-h-[300px]'} `}
                            ref={scrollRef}
                        >
                            <table className="w-full border-collapse table-auto">
                                <thead className="sticky top-0 bg-[#EEEFF2]">
                                    <tr className="bg-[#EEEFF2]">
                                        {dynamicColumns.map((col) => (
                                            <th key={col} className="px-4 py-2 border text-left text-sm font-medium text-[#0F1D40]">
                                                <div className="flex items-center justify-between">
                                                    <span>{col}</span>
                                                    <Tooltip title={`Info about ${col}`} placement="top">
                                                        <InformationCircleIcon className="ml-1 h-4 w-4 text-gray-400 cursor-pointer uniform-icon" />
                                                    </Tooltip>
                                                </div>
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody >
                                    {filteredKnownDrugs.map((row, idx) => (
                                        <tr key={idx} className="bg-red-100 hover:bg-gray-100">
                                            <td
                                                className="border px-4 py-2 text-sm text-blue-500 underline cursor-pointer"
                                                onClick={() => setDrugIdPopup(row.id)}
                                            >
                                                {row.id}
                                            </td>
                                            <td className="border px-4 py-2 text-sm text-gray-700">
                                                <div className="flex items-center">
                                                    <div className="overflow-x-auto max-w-[150px] whitespace-nowrap custom-scrollbar p-2">
                                                        {row.smiles}
                                                    </div>
                                                    <FiClipboard
                                                        className="ml-2 cursor-pointer text-gray-500 hover:text-gray-800"
                                                        onClick={() => handleCopyToClipboard(row.smiles)}
                                                        title="Copy SMILES"
                                                    />
                                                </div></td>
                                            {hasNamePropertyInKnown && (
                                                <td className="border px-4 py-2 text-sm text-gray-700 break-all">{row.name}</td>
                                            )}
                                            <td className="border px-4 py-2 text-sm text-gray-700">{row.cov.toFixed(3)}</td>
                                            <td
                                                className={`border px-4 py-2 text-sm ${row.dock === 'View'
                                                    ? 'text-blue-500 underline cursor-pointer'
                                                    : 'text-gray-700'
                                                    }`}
                                                // onClick={() => onDockSmiles(row.smiles)}
                                                onClick={() => handleCellClick(row)}
                                            >
                                                {row.dock}
                                            </td>

                                            <td className="border px-4 py-2 text-sm text-gray-700">
                                                {row.admet.radial_plot && (
                                                    <img
                                                        src={`data:image/svg+xml;base64,${row.admet.radial_plot}`}
                                                        alt="Radial Plot"
                                                        style={{ width: '75px', height: '75px', cursor: 'pointer' }}
                                                        onClick={() => onAdmetClick(row.admet, row.name)}
                                                    />
                                                )}
                                            </td>
                                            <td className="border px-4 py-2 text-sm text-gray-700">
                                                {row.admet && row.admet.molecule_svg && (
                                                    <img
                                                        src={`data:image/svg+xml;base64,${row.admet.molecule_svg}`}
                                                        alt="Molecule SVG"
                                                        style={{ width: '75px', height: '75px', cursor: 'pointer' }}
                                                        onClick={() => onAdmetClick(row.admet, row.name)}
                                                    />
                                                )}
                                            </td>
                                            {hasEditedMoleculeSvg && (
                                                <td className="border px-4 py-2 text-sm text-gray-700">
                                                    {row.editedMoleculeSvg && (
                                                        <img
                                                            src={`data:image/svg+xml;base64,${row.editedMoleculeSvg}`}
                                                            alt="Edited Molecule"
                                                            style={{ width: '75px', height: '75px', cursor: 'pointer' }}
                                                            onClick={() => onEditSmiles(row.smiles)}
                                                        />
                                                    )}
                                                </td>
                                            )}
                                            <td className="border px-4 py-2 text-sm text-gray-700">
                                                <input
                                                    type="checkbox"
                                                    checked={selectedItems.includes(row.id)}
                                                    onChange={() => handleCheckboxChange(row.id)}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>

                            </table>
                        </div>
                    </>
                )}

            {displayData.length === 0 ?
                (
                    <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mt-4 rounded-md">
                        <p className="text-l mb-2">
                            No suitable repurposable drugs found. Try lowering the threshold below <span className="font-bold">{probThreshold}</span> and try again.
                            <span className="font-medium"> (Change parameters in the settings page)</span>
                        </p>
                    </div>
                ) :
                (
                    <>
                        <h1 className="text-xl font-bold my-2 text-[#494949]">{heading}</h1>
                        <div className={`overflow-x-auto w-full custom-scrollbar overflow-y-auto ${filteredKnownDrugs.length === 0 ? 'max-h-[500px]' : 'max-h-[250px]'} `}
                            ref={scrollRef}>
                            <table className="w-full border-collapse table-auto">
                                <thead className="sticky top-0 bg-[#EEEFF2]">
                                    <tr className="bg-[#EEEFF2]">
                                        {dynamicColumns2.map((col) => (
                                            <th key={col} className="px-4 py-2 border text-left text-sm font-medium text-[#0F1D40]">
                                                <div className="flex items-center justify-between">
                                                    <span>{col}</span>
                                                    <Tooltip title={`Info about ${col}`} placement="top">
                                                        <InformationCircleIcon className="ml-1 h-4 w-4 text-gray-400 cursor-pointer uniform-icon" />
                                                    </Tooltip>
                                                </div>
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody >
                                    {displayData.map((row, idx) => (
                                        <tr key={idx} className="hover:bg-gray-100">
                                            <td
                                                className={`border px-4 py-2 text-sm ${isRowClickable ? 'text-blue-500 underline cursor-pointer' : 'text-gray-700'
                                                    }`}
                                                onClick={isRowClickable ? () => setDrugIdPopup(row.id) : undefined}
                                            >
                                                {row.id}
                                            </td>
                                            <td className="border px-4 py-2 text-sm text-gray-700">
                                                <div className="flex items-center">
                                                    <div className="overflow-x-auto max-w-[150px] whitespace-nowrap custom-scrollbar p-2">
                                                        {row.smiles}
                                                    </div>
                                                    <FiClipboard
                                                        className="ml-2 cursor-pointer text-gray-500 hover:text-gray-800"
                                                        onClick={() => handleCopyToClipboard(row.smiles)}
                                                        title="Copy SMILES"
                                                    />
                                                </div></td>
                                            {hasNameProperty && (
                                                <td className="border px-4 py-2 text-sm text-gray-700 break-all">{row.name}</td>
                                            )}
                                            <td className="border px-4 py-2 text-sm text-gray-700">{row.cov.toFixed(3)}</td>
                                            <td
                                                className={`border px-4 py-2 text-sm ${row.dock === 'View'
                                                    ? 'text-blue-500 underline cursor-pointer'
                                                    : 'text-gray-700'
                                                    }`}
                                                // onClick={() => onDockSmiles(row.smiles)}
                                                onClick={() => handleCellClick(row)}
                                            >
                                                {row.dock}
                                            </td>

                                            <td className="border px-4 py-2 text-sm text-gray-700">
                                                {row.admet.radial_plot && (
                                                    <img
                                                        src={`data:image/svg+xml;base64,${row.admet.radial_plot}`}
                                                        alt="Radial Plot"
                                                        style={{ width: '75px', height: '75px', cursor: 'pointer' }}
                                                        onClick={() => onAdmetClick(row.admet, row.name)}
                                                    />
                                                )}
                                            </td>
                                            <td className="border px-4 py-2 text-sm text-gray-700">
                                                {row.admet && row.admet.molecule_svg && (
                                                    <img
                                                        src={`data:image/svg+xml;base64,${row.admet.molecule_svg}`}
                                                        alt="Molecule SVG"
                                                        style={{ width: '75px', height: '75px', cursor: 'pointer' }}
                                                        onClick={() => onAdmetClick(row.admet, row.name)}
                                                    />
                                                )}
                                            </td>
                                            {hasEditedMoleculeSvg && (
                                                <td className="border px-4 py-2 text-sm text-gray-700">
                                                    {row.editedMoleculeSvg && (
                                                        <img
                                                            src={`data:image/svg+xml;base64,${row.editedMoleculeSvg}`}
                                                            alt="Edited Molecule"
                                                            style={{ width: '75px', height: '75px', cursor: 'pointer' }}
                                                            onClick={() => onEditSmiles(row.smiles)}
                                                        />
                                                    )}
                                                </td>
                                            )}
                                            <td className="border px-4 py-2 text-sm text-gray-700">
                                                <input
                                                    type="checkbox"
                                                    checked={selectedItems.includes(row.id)}
                                                    onChange={() => handleCheckboxChange(row.id)}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </>
                )}
            {filteredData.length > 1 && (<>
                <div className="flex justify-between mt-4 items-center">
                    <div className="text-sm text-gray-600">
                        Entries in this page: {displayData.length} <br />
                        Total entries: {filteredData.length}
                    </div>
                    <ReactPaginate
                        previousLabel={<FiChevronLeft />}
                        nextLabel={<FiChevronRight />}
                        pageCount={pageCount}
                        onPageChange={handlePageClick}
                        containerClassName={'flex items-center'}
                        pageClassName={'mx-1'}
                        activeClassName={'font-bold'}
                        previousClassName={'mr-4'}
                        nextClassName={'ml-4'}
                        breakLabel={'...'}
                        breakClassName={'mx-1'}
                    />
                </div>
            </>
            )}
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <MuiAlert onClose={handleSnackbarClose} severity="info" sx={{ width: '100%' }}>
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
            {drugIdPopup && (
                <DrugDetailsPopup drugId={drugIdPopup} onClose={handleClosePopup} />
            )}
            {isPopupOpen && (
                <DockDataPopup
                    dockData={currentDockData}
                    dockSmile={currentDocksmile}
                    onClose={closePopup}
                    selectedPdbId={selectedPdbId} />
            )}
        </div>

    );
};

export default MergeTableView;