import React, { useState, useEffect } from 'react';

const TypingEffect = ({ text, typingDelay = 100 }) => {
  const [displayedText, setDisplayedText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (currentIndex < text.length) {
      const timeoutId = setTimeout(() => {
        setDisplayedText(text.slice(0, currentIndex + 1));
        setCurrentIndex(currentIndex + 1);
      }, typingDelay);
      return () => clearTimeout(timeoutId); // Clean up the timeout
    }
  }, [currentIndex, text, typingDelay]);

  return <p>{displayedText}</p>;
};

export default TypingEffect;
